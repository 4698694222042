import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  homestayInfo: {}, 
  // ownerHomestays: []
};

const reducers = {
    storeHomestay:(state, action) => {
      state.homestayInfo = action.payload;
    },
    emptyUser: state => {
      state.homestayInfo = {};
    }
};

const currentHomestaySlice = createSlice({
  name: 'selectedHomestay',
  initialState,
  reducers,
  extraReducers: {},
})

export const { storeHomestay, emptyUser } = currentHomestaySlice.actions;
export default currentHomestaySlice.reducer;