import React from "react";
import Layout from "../../../component/home-two/Layout";
import InnerWrapper from "../../../component/home-three/InnerWrapper";
import AddNewHomestay from "../../../component/homestayDetails/AddNewHomestay";



function CreateHomestay() {
  return (
    <Layout>
      <div className="row">
        <InnerWrapper>
          <AddNewHomestay />
        </InnerWrapper>
      </div>
    </Layout>
  );
}

export default CreateHomestay;
