import React, { useState, useEffect } from "react";
// import { integrations } from "../../data/integrations";
import LoaderPage from "../templates/LoaderPage";
import { fetchAllHomestays } from "../../crud/homestay.crud";
import HomestayCard from "../cards/homestayCard";
import { Link } from "react-router-dom";
import SelectBox from "../form/SelectBox";
import { fetchAllLocations } from "../../crud/location.crud";
function HomestaysCom() {
  const [homestays, setHomestays] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(12);
  const [filteredHomestay, setFilteredHomestay] = useState([]);
  const [drpdwnLocation, setDrpdwnLocation] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("All Location");
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  useEffect(() => {
    fetchAllHomestays().then((res) => {
      setHomestays(res.data);
      setIsDataLoaded(true);
    });
  }, []);
  useEffect(() => {
    fetchAllLocations().then((res) => {
      let location = [...new Set(res.data.map(item => item.city_vil))];
      setDrpdwnLocation(location)

      setIsDataLoaded(true);
    });
  }, []);
  const handleLocationChange = (selectedValue) => {
    setSelectedLocation(selectedValue);
  };

  useEffect(() => {
    const filteredData = homestays
      .filter((transaction) => transaction.hs_name.toLowerCase().includes(inputValue.toLowerCase()))
      .filter(location => selectedLocation === "All Location" || location.hs_location.city_vil === selectedLocation)
    setFilteredHomestay(filteredData);
  }, [inputValue, homestays, selectedLocation]);
  console.log("filteredHomestay", filteredHomestay);
  return (
    <div className="row">
      <div className="my-1"><h4>Homestay</h4></div>
      <div className="crancy-body">
        <div className="crancy-dsinner">
          <div className=" crancy-table--v3 mt-1">
            <div className="crancy-customer-filter mb-0 p-0">
              <div className="crancy-header__form crancy-header__form--customer">
                <form className="crancy-header__form-inner" action="#">
                  <button className="search-btn" type="submit">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="9.78639"
                        cy="9.78614"
                        r="8.23951"
                        stroke="#9AA2B1"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></circle>
                      <path
                        d="M15.5176 15.9448L18.7479 19.1668"
                        stroke="#9AA2B1"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </button>
                  <input
                    name="s"
                    type="text"
                    placeholder="Search Homestay"
                    value={inputValue}
                    onChange={handleInputChange}
                  />

                </form>
              </div>
              <div className="col-md-3">
                <SelectBox
                  datas={["All Location", ...drpdwnLocation]}
                  selected={selectedLocation}
                  action={handleLocationChange}
                  img={<i className="fa-solid fa-chevron-down"></i>}
                />
              </div>
              <div>
                <div className="crancy-header__form crancy-header__form--customer">
                  <Link to={"/create-homestay"} className="btn btn-primary ">Add Homestay </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="crancy-body">
          <div className="crancy-dsinner">
            <div className="row">
              {!isDataLoaded ? (
                <LoaderPage />
              ) : (
                <>
                  {filteredHomestay?.map((snglhs, index) => {
                    const current = page * show;
                    const previous = current - show;
                    if (
                      previous > 0 &&
                      index + 1 > previous &&
                      index + 1 <= current
                    ) {
                      return <HomestayCard homestay={snglhs} key={index} />;
                    } else if (page == 1) {
                      return (
                        index < page * show && (
                          <HomestayCard homestay={snglhs} key={index} />
                        )
                      );
                    }
                  })}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="crancy-table-bottom">
          <div id="crancy-table__main_filter" className="dataTables_filter">
            <label>
              Search:
              <input
                type="search"
                id="loc-search"
                className="form-control form-control-sm"
                placeholder=""
                aria-controls="crancy-table__main"
              />
            </label>
          </div>
          <div
            className="dataTables_length"
            id="crancy-table__main_length"
            style={{ display: "flex" }}
          >
            <label
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <span> Show result: </span>
              <select
                name="crancy-table__main_length"
                aria-controls="crancy-table__main"
                className="form-select form-select-sm"
                defaultValue={48}
                onChange={(e) => setShow(e.target.value)}
              >
                <option value="48">48</option>
                <option value="60">60</option>
                <option value="72">72</option>
                <option value="84">84</option>
              </select>
            </label>
          </div>
          <div
            className="dataTables_paginate paging_simple_numbers"
            id="crancy-table__main_paginate"
          >
            <ul className="pagination">
              <li
                className={`paginate_button page-item previous ${page === 1 ? "disabled" : ""
                  }`}
                id="crancy-table__main_previous"
                onClick={() => page > 1 && setPage(page - 1)}
              >
                <a
                  aria-controls="crancy-table__main"
                  data-dt-idx="previous"
                  tabIndex="0"
                  className="page-link"
                >
                  <i className="fas fa-angle-left"></i>
                </a>
              </li>
              {Array.from(
                Array(Math.ceil(filteredHomestay.length / show)).keys("n")
              ).map((id, index) => (
                <li
                  className={`paginate_button page-item ${page === index + 1 ? "active" : ""
                    }`}
                  onClick={() => setPage(index + 1)}
                  key={index + "key"}
                >
                  <a
                    aria-controls="crancy-table__main"
                    data-dt-idx="0"
                    tabIndex="0"
                    className="page-link"
                  >
                    {index + 1}
                  </a>
                </li>
              ))}
              <li
                className={`paginate_button page-item next ${page === filteredHomestay.length % show < 1 ? "disabled" : ""
                  }`}
                id="crancy-table__main_next"
                onClick={() =>
                  page < Math.ceil(filteredHomestay.length / show) && setPage(page + 1)
                }
              >
                <a
                  aria-controls="crancy-table__main"
                  data-dt-idx="next"
                  tabIndex="0"
                  className="page-link"
                >
                  <i className="fas fa-angle-right"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomestaysCom;
