import React, { useEffect, useState } from "react";
import logo from "../../../assets/img/logo.png";
import logoWhite from "../../../assets/img/logo-white.png";
import logoIcon from "../../../assets/img/logo-icon.png";
import arrowIcon from "../../../assets/img/arrow-icon.svg";
// import { Link } from "react-router-dom";
import Author from "./Author";
// import Alarm from "./Alarm";
// import Message from "./Message";

function Header({ toggleMenu, menu }) {
  const [user, setUser] = useState({});


  const [subNav, setSubNav] = useState(false);

  useEffect(() => {
    let userrr = JSON.parse(localStorage.getItem('user'));
    if (userrr) {
      setUser(userrr);
    }
  }, []);
  useEffect(() => {
    if (subNav) {
      document
        .querySelector("html")
        .addEventListener("click", () => setSubNav(false));
    } else {
      document
        .querySelector("html")
        .removeEventListener("click", () => setSubNav(false));
    }
  }, [subNav]);

  //full screen
  const handleFullScreen = () => {
    const elem = document.documentElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  };
  return (
    <header className={`crancy-header ${menu && "crancy-close"}`}>
      <div className="container g-0">
        <div className="row g-0">
          <div className="col-12">
            <div className="crancy-header__inner">
              <div className="crancy-header__middle">
                <div className="crancy-header__left">
                  <div className="crancy-header__nav-bottom">
                    <div className="logo crancy-sidebar-padding">
                      <a className="crancy-logo" href="index.html">
                        <img className="crancy-logo__main" src={logo} alt="logo" />
                        <img
                          className="crancy-logo__main--dark"
                          src={logoWhite}
                          alt="#"
                        />
                        <img
                          className="crancy-logo__main--small"
                          src={logoIcon}
                          alt="#"
                        />
                        <img
                          className="crancy-logo__main--small--dark"
                          src={logoWhite}
                          alt="#"
                        />
                      </a>
                    </div>
                  </div>
                  <div
                    id="crancy__sicon"
                    className="crancy__sicon close-icon"
                    onClick={toggleMenu}
                  >
                    <img src={arrowIcon} alt="arrow icon" />
                  </div>

                </div>
                <div className="crancy-header__right">
                  <div className="crancy-header__group">
                    <div className="crancy-header__group-two">
                      <div className="crancy-header__right">
                        <div className="crancy-header__options">
                          <p>{user.name}</p>
                        </div>
                        <Author
                          id={user.id}
                          subNav={subNav}
                          setSubNav={setSubNav}
                          title="author"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
