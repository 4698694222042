import axios from './axios.interceptor';
import api from './api.util';

export const TAG = `${api}/tags`;

export function fetchAllTags() {
  return axios.get(TAG);
}
export function fetchSingleTag(slug) {
    const tagUL = `${TAG}/${slug}`;
    return axios.get(tagUL);
}

export function createTag( obj ) {
    const asd = axios.post(TAG, obj, {
      cors: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type"
      }
    });
    return asd;
}

// export async function updateTag(arr) {
//     let qwe;
//     if(arr.length === 1) {
//       let id = arr[0].id;
//       let curObj = arr[0].obj;
//       const tagUL = `${TAG}/${id}`;
//       const qwe = await axios.put(tagUL, curObj, {
//         cors: {
//           "Access-Control-Allow-Origin": "*",
//           "Access-Control-Allow-Headers": "Content-Type"
//         }
//       });
//       return qwe;
//     } else {
//       qwe = await arr.map( ar => {
//         let id = ar.id;
//         let curObj = ar.obj;
//         const tagUL = `${TAG}/${id}`;
//         return axios.put(tagUL, curObj, {
//           cors: {
//             "Access-Control-Allow-Origin": "*",
//             "Access-Control-Allow-Headers": "Content-Type"
//           }
//         });
//       });
//       return qwe;
//     }
// }
export async function updateTag(slug,obj) {
  const usrUL = `${TAG}/${slug}`;
  return axios.put(usrUL, obj, {
    cors: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type"
    }
  });
}

// export async function deleteTag(arr) {
//   let asd;
//   if(arr.length === 1) {
//     let id = arr[0]._id;
//     const tagUL = `${TAG}/${id}`;
//     asd = await axios.delete(tagUL, {
//       cors: {
//         "Access-Control-Allow-Origin": "*",
//         "Access-Control-Allow-Headers": "Content-Type"
//       }
//     });
//   } else {
//     asd = await arr.map( ar => {
//       let id = ar._id;
//       const tagUL = `${TAG}/${id}`;
//       return axios.delete(tagUL, {
//           cors: {
//             "Access-Control-Allow-Origin": "*",
//             "Access-Control-Allow-Headers": "Content-Type"
//           }
//       });
//     });
//   }
//   return asd;
// }
export async function deleteTag(slug) {
  const usrUL = `${TAG}/${slug}`;
  return axios.delete(usrUL, {
    cors: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type"
    }
  });
}