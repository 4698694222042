import React from 'react';
import { Bars } from 'react-loader-spinner';

const LoaderPage = () => {
    return (
        <div style={{marginLeft:'150px'}}>
            <div md={4} className="d-flex justify-content-center align-items-center">
                <span>
                    <Bars heigth="40" width="40" color="#0a82fd" ariaLabel="loading....." />
                </span>
            </div>
        </div> 
    )
}
export default LoaderPage;