import React from "react";
// import SelectInput from "../../../component/form/SelectInput";
// import DoughnutChart from "../../../component/chart/Doughnut";
// import { projects } from "../../../data/projects";
// import TaskCard from "../../../component/cards/TaskCard";
import LocationTagsCom from "../../../component/tags/LocationTagsCom";



function LocationTag() {
  return (
    <div className="tab-pane fade show active">
      <LocationTagsCom/>
    </div>
  );
}

export default LocationTag;
