import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  ownerInfo: {}, 
  ownerHomestays: []
};

const reducers = {
    storeUser:(state, action) => {
      state.ownerInfo = action.payload;
    },
    storeHomestay:(state, action) => {
      state.ownerHomestays = action.payload;
    },
    emptyUser: state => {
      state.ownerInfo = {};
      state.ownerHomestays = [];
    }
};

const currentOwwnerSlice = createSlice({
  name: 'selectedOwner',
  initialState,
  reducers,
  extraReducers: {},
})

export const { storeUser, storeHomestay, emptyUser } = currentOwwnerSlice.actions;
export default currentOwwnerSlice.reducer;