import React, { useState } from "react";
import Layout from "../../component/home-two/Layout";
import InnerWrapper from "../../component/home-three/InnerWrapper";
import LocationsCom from "../../component/locations/LocationsCom";
import useMenu from "../../hooks/useMenu";
import AddNewLocation from "../../component/locations/AddNewLocation";

function Locations() {
  useMenu();
  const [addTask, setAddTask] = useState(false);
  return (
    <Layout>
      <div className="row">
        <InnerWrapper>
          <AddNewLocation show={addTask} handleShow={setAddTask} />
          <LocationsCom addTask={setAddTask}/>
        </InnerWrapper>
      </div>
    </Layout>
  );
}

export default Locations;
