import React, { useEffect, useState } from "react";
import SelectInput from "../form/SelectInput";
import seller1 from "../../assets/img/seller-1.png";
import { fetchAllHomestays } from "../../crud/homestay.crud";
import { fetchDashboardData } from "../../crud/dashboard.crud";
import { Link } from "react-router-dom";

function TopHomestayDash() {
  const [homestays, setHomestays] = useState([]);
  useEffect(() => {
    fetchDashboardData().then((res) => {
      console.log("popularHomestay", res.data.popularHomestay);
      setHomestays(res.data.popularHomestay);
    });
  }, []);
  // console.log("homestays",homestays);
  return (
    <div className="col-xl-12 col-lg-6 col-md-6 col-12 crancy-sidebar__widget">
      <div className="crancy-sidebar__single">
        <div className="crancy-sidebar__heading">
          <h4 className="crancy-sidebar__title">Top Homestays</h4>
          <Link to={"/homestays"} className="btn btn-primary w-25">View All</Link>
        </div>
        <div className="crancy-sidebar__creators">
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="seller_tab-3"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <ul className="crancy-sidebar__creatorlist crancy-sidebar__creatorlist--sellers">
              {homestays.map((item,index)=>(
                <li key={index}>
                  <div className="crancy-sidebar__creator">
                    <a href={`/homestays/${item.homestay_slug}`}>
                      <b className="crancy-sidebar__creator-name">
                       {item.hs_name}
                      </b>
                      <span className="crancy-sidebar__creator-badge crancy-color1">
                        view
                      </span>
                    </a>
                  </div>
                </li>
              )).slice(0,8)}
                

              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopHomestayDash;
