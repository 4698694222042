import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import HomestayOnrCard from "../../../component/cards/HomestayOnrCard";

function SettingsFaq() {

  const onrHomestays = useSelector(state => state.selectedOwner.ownerHomestays );
  const location = useLocation();
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");
  const ownerId = pathSegments.length > 0 ? pathSegments[1] : null;
  return (
    <div className="tab-pane fade show active">
      <div
        className="crancy-accordion accordion accordion-flush crancy__item-group"
        id="crancy-accordion"
      >
      <div className="row">

        <Link to={`/owners/${ownerId}/owner-homestays/create`} className="btn btn-primary">Add Homestay</Link>
      </div>
        <div className="row">
          {onrHomestays?.map((onrhs, index) => {
            return <HomestayOnrCard homestay={onrhs} key={index} />
          })}
        </div>
      </div>
    </div>
  );
}

export default SettingsFaq;
