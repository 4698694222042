import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoaderPage from "../templates/LoaderPage";
import { createHomestay } from "../../crud/homestay.crud";
import { fetchAllLocations } from "../../crud/location.crud";
import { fetchHomestayOwner } from "../../crud/homestayowner.crud";
import { fetchAllTags } from "../../crud/tag.crud";
import { useDispatch } from "react-redux";
import { storeHomestay } from "../../redux/features/currentHomestaySlice";
import Select from "react-select";

function AddNewHomestay({ className }) {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [homestayDetils, setHomestayDetils] = useState([]);
  // const [checkDetils, setCheckDetils] = useState([{ hs_pincode: '', hs_extraInfo: { spaceOffered: '' } }]);
  const [allLocation, setAllLocation] = useState({});
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [activeAccordion, setActiveAccordion] = useState(1);
  const [hsTags, setHsTags] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [tags, setTags] = useState([]);
  const [oriLocs, setOriLocs] = useState([]);
  const [owner, setOwner] = useState([]);
  const [locations, setLocations] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [folderName, setFolderName] = useState('');
  
  const [values, setValues] = useState({
    hs_approved: true,
    hs_name: "",
    homestay_slug: "",
    hs_address: "",
    hs_pincode: "",
    hs_contact: "",
    hs_email: "",
    hs_pics: [],
    // "hs_video": "",
    hs_location: {
      city_vil: "",
      locSlug: "",
      district: "",
      state: "",
    },
    hs_ownedBy: "",
    // "hs_rooms": [],
    hs_extraInfo: {
      spaceOffered: "Private Room",
      kitchenAvailability: "Unavailable",
      checkinTime: "12.00 PM",
      checkoutTime: "10.00 AM",
      checkinRequired: "Original Govt. issued photo ID",
      petAllowed: "No",
      cancellationPolicy: "Flexible",
      acAvailability: "No",
      parking: "Free",
      mountainView: "No mountain View",
    },
    hs_price: 0,
    hs_discount: 0,
    hs_roomCount: 1,
    tags: [],
    // "hs_review": []
  });
  const currentDate = Date.now();
  useEffect(() => {
    fetchAllTags().then((res) => {
      let arr = [];
      let ltgs = res.data.filter((tgg) => tgg.tagType === "homestay");
      ltgs.map((asd) => {
        let obj = {
          label: asd.tagName,
          value: asd.tagName,
        };
        arr.push(obj);
        return true;
      });
      setTags(arr);
    });
    fetchAllLocations().then((res) => {
      let arr = [];
      res.data.map((qwe) => {
        let obj = {
          label: qwe.city_vil,
          value: qwe.city_vil,
        };
        arr.push(obj);
        return true;
      });
      setOriLocs(res.data);
      setLocations(arr);
    });
    fetchHomestayOwner().then((res) => {
      let arr = [];
      res.data.map((qwe) => {
        let obj = {
          label: qwe.name,
          value: qwe._id,
        };
        arr.push(obj);
        return true;
      });
      setOwner(arr);
    });
  }, []);
  const handleChange = (e) => {
    var name = e.target.name;
    var val = e.target.value;
    let newVal = {};
    switch (name) {
      case "name":
        newVal = { ...values, hs_name: val };
        break;
      case "address":
        newVal = { ...values, hs_address: val };
        break;
      case "pincode":
        newVal = { ...values, hs_pincode: val };
        break;
      case "phone":
        newVal = { ...values, hs_contact: val };
        break;
      case "email":
        newVal = { ...values, hs_email: val };
        break;
      case "price":
        newVal = { ...values, hs_price: val };
        break;
      case "discount":
        newVal = { ...values, hs_discount: val };
        break;
      case "roomCount":
        newVal = { ...values, hs_roomCount: val };
        break;
      case "kitchenAvailability":
        let obb1 = values.hs_extraInfo;
        let newObj1 = { ...obb1, kitchenAvailability: val };
        newVal = { ...values, hs_extraInfo: newObj1 };
        break;
      case "spaceOffered":
        let obb2 = values.hs_extraInfo;
        let newObj2 = { ...obb2, spaceOffered: val };
        newVal = { ...values, hs_extraInfo: newObj2 };
        break;
      case "checkinTime":
        let obb3 = values.hs_extraInfo;
        let newObj3 = { ...obb3, checkinTime: val };
        newVal = { ...values, hs_extraInfo: newObj3 };
        break;
      case "checkoutTime":
        let obb4 = values.hs_extraInfo;
        let newObj4 = { ...obb4, checkoutTime: val };
        newVal = { ...values, hs_extraInfo: newObj4 };
        break;
      case "petAllowed":
        let obb5 = values.hs_extraInfo;
        let newObj5 = { ...obb5, petAllowed: val };
        newVal = { ...values, hs_extraInfo: newObj5 };
        break;
      case "checkinRequired":
        let obb6 = values.hs_extraInfo;
        let newObj6 = { ...obb6, checkinRequired: val };
        newVal = { ...values, hs_extraInfo: newObj6 };
        break;
      case "cancellationPolicy":
        let obb7 = values.hs_extraInfo;
        let newObj7 = { ...obb7, cancellationPolicy: val };
        newVal = { ...values, hs_extraInfo: newObj7 };
        break;
      case "acAvailability":
        let obb8 = values.hs_extraInfo;
        let newObj8 = { ...obb8, acAvailability: val };
        newVal = { ...values, hs_extraInfo: newObj8 };
        break;
      case "parking":
        let obb9 = values.hs_extraInfo;
        let newObj9 = { ...obb9, parking: val };
        newVal = { ...values, hs_extraInfo: newObj9 };
        break;
      case "mountainView":
        let obb10 = values.hs_extraInfo;
        let newObj10 = { ...obb10, mountainView: val };
        newVal = { ...values, hs_extraInfo: newObj10 };
        break;
      default:
        break;
    }
    setValues(newVal);
  };
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const folderNamee = folderName;
    
    const path = `/${folderNamee}/`; // Replace with your desired path

    const renamedFiles = files.map((file, index) => {
      const fileExtension = file.name.split('.').pop();
      const newName = `${path}${index + 1}.${fileExtension}`;
      // return new File([file], newName, { type: file.type });
      return newName;
    });
    setSelectedImages(renamedFiles);

    let newVal = {...values,hs_pics:renamedFiles}
    setValues(newVal);
  };
  const handleChangeSelect = (val, ttt) => {
    if (ttt === "loc") {
      let nObj = {};
      oriLocs.map((lc) => {
        if (lc.city_vil === val.label) {
          nObj = {
            city_vil: lc.city_vil,
            locSlug: lc.locSlug,
            district: lc.district,
            state: lc.state,
          };
        }
        return true;
      });
      let hsSlug = nObj["locSlug"] + currentDate;
      setFolderName(currentDate);
      let newVal = { ...values, hs_location: nObj, homestay_slug: hsSlug };
      setValues(newVal);
    } else if (ttt === "tag") {
      let arrtg = [];
      val.map((vv) => arrtg.push(vv.label));
      let newVal = { ...values, tags: arrtg };
      setValues(newVal);
    }
  };
  const handleChangeOwnerSelect = (val, ttt) => {
    if (ttt === "own") {
      let nOwn = "";
      owner.map((lc) => {
        if (lc.label === val.label) {
          nOwn = lc.value;
        }
        return true;
      });
      let newVal = { ...values, hs_ownedBy: nOwn };
      setValues(newVal);
    }
  };

  const createHomestaySubmit = (e) => {
    createHomestay(values)
      .then((res) => {
        history("/homestays");
      })
      .catch((err) => {
        console.log(err);
      });
    setButtonClicked(true);
    setTimeout(() => {
      setButtonClicked(false);
    }, 8000);
  };
  return (
    <div
      className={className ? className : "col-xxl-9 col-12 crancy-main__column"}
    >
      <div className="row">
        <div className="crancy-body">
          <div className="crancy-upcontent">
            <div className="col-12">
              <div
                className="crancy-accordion accordion accordion-flush"
                id="crancy-accordion"
              >
                <div
                  className={`accordion-item crancy-accordion__single ${
                    activeAccordion === 1 ? "active" : ""
                  }`}
                >
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button crancy-accordion__heading"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordionContent"
                      aria-expanded={activeAccordion === 1}
                      aria-controls="accordionContent"
                      onClick={() => setActiveAccordion(1)}
                    >
                      Basic Info
                    </button>
                  </h2>
                  <div
                    id="accordionContent"
                    className={`accordion-collapse collapse ${
                      activeAccordion === 1 ? "show" : ""
                    }`}
                    aria-labelledby="accordionHeader"
                    data-bs-parent="#crancy-accordion"
                  >
                    <div
                      className="accordion-body crancy-accordion__body"
                      // key={index}
                    >
                      <div className="crancy__item-form--group">
                        <div className="row">
                          <div className="col-lg-6 col-12">
                            <div className="crancy__item-form--group mg-top-form-20">
                              <label className="crancy__item-label">
                                Homestay Name
                              </label>
                              <input
                                className="crancy__item-input"
                                type="text"
                                name="name"
                                id="name"
                                value={values.hs_name}
                                onChange={handleChange}
                                required="required"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-12">
                            <div className="crancy__item-form--group mg-top-form-20">
                              <label className="crancy__item-label">
                                Address
                              </label>
                              <input
                                className="crancy__item-input"
                                type="text"
                                name="address"
                                id="address"
                                value={values.hs_address}
                                onChange={handleChange}
                                required="required"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-12">
                            <div className="crancy__item-form--group mg-top-form-20">
                              <label className="crancy__item-label">
                                Location
                              </label>
                              <Select
                                className="mb-1 col-12"
                                placeholder="Select Location"
                                closeMenuOnSelect={true}
                                name="location"
                                id="location"
                                onChange={(newValue) =>
                                  handleChangeSelect(newValue, "loc")
                                }
                                options={locations}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-12">
                            <div className="crancy__item-form--group mg-top-form-20">
                              <label className="crancy__item-label">
                                PinCode
                              </label>
                              <input
                                className="crancy__item-input"
                                type="text"
                                name="pincode"
                                id="pincode"
                                value={values.hs_pincode}
                                onChange={handleChange}
                                required="required"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-12">
                            <div className="crancy__item-form--group mg-top-form-20">
                              <label className="crancy__item-label">
                                Homestay Contact
                              </label>
                              <input
                                className="crancy__item-input"
                                type="text"
                                name="phone"
                                id="phone"
                                value={values.hs_contact}
                                onChange={handleChange}
                                required="required"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-12">
                            <div className="crancy__item-form--group mg-top-form-20">
                              <label className="crancy__item-label">
                                Homestay Email
                              </label>
                              <input
                                className="crancy__item-input"
                                type="text"
                                name="email"
                                id="email"
                                value={values.hs_email}
                                onChange={handleChange}
                                required="required"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-12">
                            <div className="crancy__item-form--group mg-top-form-20">
                              <label className="crancy__item-label">
                                Picture
                              </label>
                              <input
                                className=""
                                type="file"
                                name="pics"
                                id="pics"
                                // value={values.hs_pics}
                                onChange={handleFileChange}
                                placeholder="Pictures"
                                multiple
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-12">
                            <div className="crancy__item-form--group mg-top-form-20">
                              <label className="crancy__item-label">
                                Video
                              </label>
                              <input
                                className="crancy__item-input"
                                type="text"
                                name="video"
                                id="video"
                                value={values.hs_video}
                                onChange={handleChange}
                                placeholder="Video"
                                required="required"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-12">
                            <div className="crancy__item-form--group mg-top-form-20">
                              <label className="crancy__item-label">
                                Price
                              </label>
                              <input
                                className="crancy__item-input"
                                type="number"
                                name="price"
                                id="price"
                                value={values.hs_price}
                                onChange={handleChange}
                                placeholder="Minimum Price"
                                required="required"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-12">
                            <div className="crancy__item-form--group mg-top-form-20">
                              <label className="crancy__item-label">
                                Discount
                              </label>
                              <input
                                className="crancy__item-input"
                                type="number"
                                name="discount"
                                id="discount"
                                value={values.hs_discount}
                                onChange={handleChange}
                                placeholder="Discount"
                                required="required"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-12">
                            <div className="crancy__item-form--group mg-top-form-20">
                              <label className="crancy__item-label">
                                Rooms
                              </label>
                              <input
                                className="crancy__item-input"
                                type="number"
                                name="roomCount"
                                id="roomCount"
                                value={values.hs_roomCount}
                                onChange={handleChange}
                                placeholder="Number of rooms"
                                min="1"
                                required="required"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-12">
                            <div className="crancy__item-form--group mg-top-form-20">
                              <label className="crancy__item-label">
                                Location
                              </label>
                              <Select
                                className="mb-1 col-12"
                                placeholder="Select Owner"
                                closeMenuOnSelect={true}
                                name="owner"
                                id="owner"
                                onChange={(newValue) =>
                                  handleChangeOwnerSelect(newValue, "own")
                                }
                                options={owner}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`accordion-item crancy-accordion__single ${
                    activeAccordion === 2 ? "active" : ""
                  }`}
                >
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button crancy-accordion__heading"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordionContent2"
                      aria-expanded={activeAccordion === 2}
                      aria-controls="accordionContent2"
                      onClick={() => setActiveAccordion(2)}
                    >
                      Extra Info
                    </button>
                  </h2>
                  <div
                    id="accordionContent2"
                    className={`accordion-collapse collapse ${
                      activeAccordion === 2 ? "show" : ""
                    }`}
                    aria-labelledby="accordionHeader"
                    data-bs-parent="#crancy-accordion"
                  >
                    <div className="accordion-body crancy-accordion__body">
                      <div className="crancy__item-form--group">
                        <div className="row">
                          <div className="col-lg-6 col-12">
                            <div className="crancy__item-form--group mg-top-form-20">
                              <label className="crancy__item-label">
                                Kitchen Availability
                              </label>
                              <input
                                className="crancy__item-input"
                                type="text"
                                name="kitchenAvailability"
                                id="kitchenAvailability"
                                value={values.hs_extraInfo.kitchenAvailability}
                                onChange={handleChange}
                                placeholder="Kitchen Availability"
                                required="required"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-12">
                            <div className="crancy__item-form--group mg-top-form-20">
                              <label className="crancy__item-label">
                                Space Offered
                              </label>
                              <input
                                className="crancy__item-input"
                                type="text"
                                name="spaceOffered"
                                id="spaceOffered"
                                value={values.hs_extraInfo.spaceOffered}
                                onChange={handleChange}
                                placeholder="Space Offered"
                                required="required"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-12">
                            <div className="crancy__item-form--group mg-top-form-20">
                              <label className="crancy__item-label">
                                Checkin Time
                              </label>
                              <input
                                className="crancy__item-input"
                                type="text"
                                name="checkinTime"
                                id="checkinTime"
                                value={values.hs_extraInfo.checkinTime}
                                onChange={handleChange}
                                placeholder="Checkin Time"
                                required="required"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-12">
                            <div className="crancy__item-form--group mg-top-form-20">
                              <label className="crancy__item-label">
                                Checkout Time
                              </label>
                              <input
                                className="crancy__item-input"
                                type="text"
                                name="checkoutTime"
                                id="checkoutTime"
                                value={values.hs_extraInfo.checkoutTime}
                                onChange={handleChange}
                                placeholder="Checkout Time"
                                required="required"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-12">
                            <div className="crancy__item-form--group mg-top-form-20">
                              <label className="crancy__item-label">
                                Pet Allowed
                              </label>
                              <input
                                className="crancy__item-input"
                                type="text"
                                name="petAllowed"
                                id="petAllowed"
                                value={values.hs_extraInfo.petAllowed}
                                onChange={handleChange}
                                placeholder="Pet Allowed"
                                required="required"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-12">
                            <div className="crancy__item-form--group mg-top-form-20">
                              <label className="crancy__item-label">
                                Checkin Required
                              </label>
                              <input
                                className="crancy__item-input"
                                type="text"
                                name="checkinRequired"
                                id="checkinRequired"
                                value={values.hs_extraInfo.checkinRequired}
                                onChange={handleChange}
                                placeholder="Checkin Required"
                                required="required"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-12">
                            <div className="crancy__item-form--group mg-top-form-20">
                              <label className="crancy__item-label">
                                Cancellation Policy
                              </label>
                              <input
                                className="crancy__item-input"
                                type="text"
                                name="cancellationPolicy"
                                id="cancellationPolicy"
                                value={values.hs_extraInfo.cancellationPolicy}
                                onChange={handleChange}
                                placeholder="Cancellation Policy"
                                required="required"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-12">
                            <div className="crancy__item-form--group mg-top-form-20">
                              <label className="crancy__item-label">
                                AC Availability
                              </label>
                              <input
                                className="crancy__item-input"
                                type="text"
                                name="acAvailability"
                                id="acAvailability"
                                value={values.hs_extraInfo.acAvailability}
                                onChange={handleChange}
                                placeholder="AC Availability"
                                required="required"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-12">
                            <div className="crancy__item-form--group mg-top-form-20">
                              <label className="crancy__item-label">
                                Parking
                              </label>
                              <input
                                className="crancy__item-input"
                                type="text"
                                name="parking"
                                id="parking"
                                value={values.hs_extraInfo.parking}
                                onChange={handleChange}
                                placeholder="Parking"
                                required="required"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-12">
                            <div className="crancy__item-form--group mg-top-form-20">
                              <label className="crancy__item-label">
                                Mountain View
                              </label>
                              <input
                                className="crancy__item-input"
                                type="text"
                                name="mountainView"
                                id="mountainView"
                                value={values.hs_extraInfo.mountainView}
                                onChange={handleChange}
                                placeholder="Mountain View"
                                required="required"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`accordion-item crancy-accordion__single ${
                    activeAccordion === 2 ? "active" : ""
                  }`}
                >
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button crancy-accordion__heading"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordionContent3"
                      aria-expanded={activeAccordion === 3}
                      aria-controls="accordionContent3"
                      onClick={() => setActiveAccordion(3)}
                    >
                      Tags
                    </button>
                  </h2>
                  <div
                    id="accordionContent3"
                    className={`accordion-collapse collapse ${
                      activeAccordion === 3 ? "show" : ""
                    }`}
                    aria-labelledby="accordionHeader"
                    data-bs-parent="#crancy-accordion"
                  >
                    <div className="accordion-body crancy-accordion__body">
                      <div className="crancy__item-form--group">
                        <div className="row">
                          <div className=" col-12">
                            <div className="crancy__item-form--group mg-top-form-20">
                              <label className="crancy__item-label">Tags</label>
                              <Select
                                closeMenuOnSelect={false}
                                placeholder="Select Tags"
                                // components={animatedComponents}
                                name="tags"
                                id="homestay-tag"
                                onChange={(newValue) =>
                                  handleChangeSelect(newValue, "tag")
                                }
                                isMulti
                                options={tags}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="crancy-btn crancy-color8__bg"
              type="submit"
              onClick={() => createHomestaySubmit()}
              disabled={buttonClicked}
              style={{ cursor: buttonClicked ? 'not-allowed' : 'pointer' }}
            >
              <svg
                width="26"
                height="30"
                viewBox="0 0 26 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.2484 25.8438C18.5393 25.8472 23.4162 21.5133 24.045 16.2064C24.559 11.8667 23.0512 8.36438 19.6548 5.6833C19.0487 5.20482 18.9054 4.70219 19.2659 4.22716C19.6172 3.76249 20.1767 3.73373 20.7634 4.20071C25.0411 7.61331 26.7672 12.0576 25.6858 17.452C24.6613 22.5657 20.4371 26.5753 15.443 27.4793C14.7153 27.6116 13.9841 27.699 13.2439 27.7197C13.2462 27.094 13.2473 26.4683 13.2484 25.8438Z"
                  fill="white"
                />
                <path
                  d="M12.7651 3.59243C7.91199 3.64879 3.17832 7.14075 2.11629 12.3707C1.17592 17.0048 2.66891 20.8142 6.34623 23.7379C6.69304 24.014 6.97618 24.2935 6.90454 24.7812C6.79652 25.5127 6.02671 25.7956 5.37289 25.3252C3.90151 24.2682 2.70757 22.9455 1.78767 21.3755C-2.76862 13.6025 1.81951 3.52227 10.6285 1.93961C11.3392 1.81194 12.0521 1.74063 12.7707 1.69922C12.7685 2.33067 12.7662 2.96212 12.7651 3.59243Z"
                  fill="white"
                />
                <path
                  d="M19.2544 11.9937C19.2647 12.5941 19.0702 12.976 18.7177 13.2716C16.4049 15.2166 14.0989 17.1696 11.7679 19.0927C10.9435 19.7725 9.85758 19.6183 9.19921 18.7695C8.46352 17.8217 7.75171 16.8544 7.05354 15.8768C6.54526 15.1648 6.68058 14.2757 7.33213 13.7857C7.99732 13.2854 8.8308 13.4039 9.39252 14.1078C9.79618 14.6139 10.1885 15.1314 10.5489 15.6686C10.7661 15.9941 10.9139 15.978 11.1982 15.7364C12.9345 14.2573 14.6868 12.7977 16.4356 11.3335C16.6824 11.1265 16.9143 10.8976 17.2156 10.7665C18.1776 10.3455 19.251 11.0575 19.2544 11.9937Z"
                  fill="white"
                />
                <path
                  d="M13.2483 25.8439C13.2472 26.4696 13.2449 27.0953 13.2437 27.7199C13.2415 28.1408 13.2517 28.5629 13.2335 28.9839C13.2142 29.4198 13.005 29.5429 12.6525 29.3037C11.5836 28.5802 10.525 27.8395 9.46523 27.0999C9.173 26.8963 9.17186 26.6663 9.46409 26.4616C10.5238 25.7231 11.5813 24.9813 12.6513 24.259C13.0061 24.0197 13.213 24.1428 13.2335 24.5799C13.2528 25.0008 13.2449 25.4218 13.2483 25.8439Z"
                  fill="white"
                />
                <path
                  d="M12.7651 3.59318C12.7663 2.96288 12.7685 2.33143 12.7697 1.70113C12.7708 1.26176 12.7572 0.821235 12.7765 0.381863C12.7924 0.0115033 12.9982 -0.105816 13.3064 0.102368C14.3889 0.836187 15.4646 1.58266 16.5391 2.32913C16.8325 2.53271 16.8313 2.7639 16.5402 2.96633C15.4657 3.71395 14.3923 4.46158 13.3075 5.19424C13.005 5.39783 12.7924 5.28511 12.7765 4.91245C12.7572 4.47423 12.7674 4.03371 12.7651 3.59318Z"
                  fill="white"
                />
              </svg>
              Create Homestay
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewHomestay;
