import React, { useEffect, useState } from "react";
import { fetchHomestayOwner } from "../../crud/homestayowner.crud";
import { Link } from "react-router-dom";
function OwnerDash() {

  const [users, setUsers] = useState([]);
  useEffect(() => {
    fetchHomestayOwner().then((res) => {
      let loc = [...new Set(res.data.map((item) => item.city_vil))];
      loc.sort(function (a, b) {
        return a.localeCompare(b);
      });
      setUsers(res.data);
    });
  }, []);
  return (
    <div className="col-xl-12 col-lg-6 col-md-6 col-12 crancy-sidebar__widget">
      <div className="crancy-sidebar__single">
        <div className="crancy-sidebar__heading ">
          <h4 className="crancy-sidebar__title">Top Owners</h4>
          <Link to={"/owners"} className="btn btn-primary w-25">View All</Link>
        </div>
        
        <div className="crancy-sidebar__creators">
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="seller_tab-1"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <ul className="crancy-sidebar__creatorlist">
              {users.map((item,index)=>(
                <li key={index}>
                  <div className="crancy-sidebar__creator">
                    <a href="#">
                      <span className="crancy-sidebar__creator-link">
                        {item.city_vil}
                      </span>
                      <b className="crancy-sidebar__creator-name">View</b>
                    </a>
                  </div>
                </li>
              )).slice(0,8)}
               
                
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OwnerDash;
