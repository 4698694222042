
import FullCalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { useState } from "react";

function CalenderSm() {
  const [hoveredTitle, setHoveredTitle] = useState(null);
  const events =[
    {
      title:"demo title",
      start:"2024-01-24",
      end:"2024-01-24",
    }
  ]
  const listEvent = [
    {
      title: 'Meeting',
      start: '2024-01-24T14:30:00',
      extendedProps: {
        status: 'done'
      }
    },
    {
      title: 'Booking',
      start: '2024-01-24T10:30:00',
      extendedProps: {
        status: 'done'
      }
    },
    {
      title: 'Birthday Party',
      start: '2024-01-27T07:00:00',
      backgroundColor: 'green',
      borderColor: 'green'
    }
  ]
  // const eventRender = ({ event, el }) => {
  //   // Highlight dates with events in green
  //   el.style.backgroundColor = 'green';
  // };
  return (
    <>
    <div className="col-md-8 col-12 ">
      <div className="crancy-sidebar__single">
        <div className="crancy-default-cd">
          <FullCalendar
            selectable={true}
            plugins={[daygridPlugin]}
            views={["dayGridDay"]}
            headerToolbar={{
              start: "title",
              end: "prev next",
            }}
            initialDate={new Date()} // using new Date(); and set your scheduled on event array
            businessHours={true} // display business hours
            editable={false}
            events={listEvent}
            // eventDidMount={eventRender}
          />
        </div>
      </div>
    </div>
    <div className="col-md-4">
    <FullCalendar
        editable
        selectable
        events={listEvent}
        initialView="listWeek"
        // defaultView="listWeek"
        // headerToolbar={{
        //   start: "prev next",
        //   center: "title",
        //   end: "listWeek",
        // }}
        plugins={[timeGridPlugin,listPlugin,]}
        views={[
          // "dayGridMonth",
          // "timeGridWeek",
          // "timeGridDay",
          "listDay",
          "listWeek",
        ]}
        // validRange={[
        //   {
        //     start: "2023-05-11",
        //     end: "2023-06-11",
        //   },
        // ]}
      />
    </div>
    </>
  );
}

export default CalenderSm;
