import { useEffect } from "react";
import { useNavigate } from "react-router-dom";




function ProtectedRoute(props) {
    const {Element} = props;
    const navigate = useNavigate();
    useEffect(()=>{
        let token =localStorage.getItem('token');
        if (!token) {
          navigate('/login');
        //   return null;
        }
    })
  return (
    <div>
      <Element />
    </div>
  );
}
export default ProtectedRoute;
