import React from "react";
// import SelectInput from "../../../component/form/SelectInput";
// import DoughnutChart from "../../../component/chart/Doughnut";
// import { projects } from "../../../data/projects";
// import TaskCard from "../../../component/cards/TaskCard";
import HomestayInfoCom from "../../../component/homestayDetails/HomestayInfoCom";



function HomestayInfo() {
  return (
    <div className="tab-pane fade show active">
      <HomestayInfoCom/>
    </div>
  );
}

export default HomestayInfo;
