import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import UpdateQueries from "./UpdateQueries";
import DeleteCom from "./DeleteCom";

function QueryItem({ queryy }) {
  const { _id, location, homestay, name, pax, bookingDate, advance, createdAt } = queryy;
  let bookDate = moment(bookingDate).utc().format('DD MMM, YY')
  let created = moment(createdAt);
  const qryDate = created.fromNow()
  const [isUpdateLocationOpen, setIsUpdateLocationOpen] = useState(false);
  const [isDeleteLocationOpen, setIsDeleteLocationOpen] = useState(false);
  let chkid = "checkbox" + _id;
  const updateModal = (isOpen) => {
    setIsUpdateLocationOpen(isOpen);
  };
  const deleteModal = (isOpen) =>{
    setIsDeleteLocationOpen(isOpen)
  }
  return (
    <>
      <tr>
        <td className="crancy-table__column-1 crancy-table__data-1">
          <div className="crancy-table__customer">
            <div className="crancy-wc__checkbox">
              <label htmlFor={chkid} className="crancy-table__customer-img">
                <h4 className="crancy-table__product-title">{qryDate}</h4>
              </label>
            </div>
          </div>
        </td>
        <td className="crancy-table__column-2 crancy-table__data-2">
          <h4 className="crancy-table__product-title">{name}</h4>
        </td>
        <td className="crancy-table__column-3 crancy-table__data-3">
          <h4 className="crancy-table__product-title">{pax}</h4>
        </td>
        <td className="crancy-table__column-3 crancy-table__data-3">
          <h4 className="crancy-table__product-title">{homestay[0].hs_name}, {location[0].city_vil}</h4>
        </td>
        <td className="crancy-table__column-3 crancy-table__data-3">
          <h4 className="crancy-table__product-title">{bookDate}</h4>
        </td>
        <td className="crancy-table__column-3 crancy-table__data-3">
          <h4 className="crancy-table__product-title">
            {advance? <span className="badge bg-success">Confirmed</span> : <span className="badge bg-danger">New</span>
            }
          </h4>
        </td>
        <td className="crancy-table__column-3 crancy-table__data-3">
          <Link onClick={() => updateModal(true)}>
            <i
              className="fa fa-solid fa-pen-to-square text-success"
              aria-hidden="true"
            ></i>
          </Link>
          <Link onClick={() => deleteModal(true)}>
          <i className="fa fa-trash text-danger ms-2" aria-hidden="true"></i>
          </Link>
        </td>
      </tr>
      {isUpdateLocationOpen && (
        <UpdateQueries
          id={_id}
          show={isUpdateLocationOpen}
          handleShow={updateModal}
        />
      )}
      {isDeleteLocationOpen && (
        <DeleteCom
          id={_id}
          show={isDeleteLocationOpen}
          handleShow={deleteModal}
        />
      )}
    </>
  );
}

export default QueryItem;
