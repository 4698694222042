import React, { useState, useEffect } from "react";
import QueryItem from "./QueryItem";
import LoaderPage from "../templates/LoaderPage";
import SelectBox from "../form/SelectBox";
import { fetchAllQueries } from "../../crud/query.crud";

function BookingQueriesCom({ className, addTask }) {
  const [drpdwnDistrict, setDrpdwnDistrict] = useState([]);
  const [drpdwnState, setDrpdwnState] = useState([]);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(25);
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [selectedDistrict, setSelectedDistrict] = useState("All District");
  const [selectedState, setSelectedState] = useState("All State");
  const [inputValue, setInputValue] = useState('');
  const [updateTask, setUpdateTask] = useState(false);
  const handleInputChange  = (e) => {
    setInputValue(e.target.value);
  };
  const handleDistrictChange = (selectedValue) => {
    setSelectedDistrict(selectedValue);
  };
  const handleStateChange = (selectedValue) => {
    setSelectedState(selectedValue);
  };
  useEffect(() => {
    fetchAllQueries().then((res) => {
      let dist = [...new Set(res.data.map(item => item.district))];
      let stat = [...new Set(res.data.map(item => item.state))];
      setLocations(res.data);
      setDrpdwnDistrict(dist)
      setDrpdwnState(stat)
      setIsDataLoaded(true);
    });
  }, []);
  useEffect(() => {
      const filteredData = locations
      setFilteredLocations(filteredData);
  }, [selectedDistrict,selectedState,inputValue, locations]);
  return (
    <div className={className ? className : "col-xxl-9 col-12 crancy-main__column"} >
      <div className="crancy-body">
        <div className="crancy-dsinner">
          <div className="crancy-table crancy-table--v3 mt-3">
            <div className="crancy-customer-filter">
              <div
                className={`crancy-customer-filter__single ${
                  className
                    ? "crancy-customer-filter__single--csearch--history"
                    : "crancy-customer-filter__single--csearch"
                }`}
              >
                <div className="crancy-header__form crancy-header__form--customer">
                  <form className="crancy-header__form-inner" action="#">
                    <button className="search-btn" type="submit">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="9.78639"
                          cy="9.78614"
                          r="8.23951"
                          stroke="#9AA2B1"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></circle>
                        <path
                          d="M15.5176 15.9448L18.7479 19.1668"
                          stroke="#9AA2B1"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </button>
                    <input
                      name="s"
                      type="text"
                      value={inputValue}
                      onChange={handleInputChange}
                      placeholder="Search by Location Name"
                    />
                  </form>
                </div>
              </div>
              <div className="col-md-3">
                <SelectBox
                  datas={["All District",...drpdwnDistrict]}
                  value={selectedDistrict}
                  action={handleDistrictChange}
                  img={<i className="fa-solid fa-chevron-down"></i>}
                />
              </div>
              <div className="col-md-3">
                <SelectBox
                  datas={["All State",...drpdwnState]}
                  action={handleStateChange}
                  img={<i className="fa-solid fa-chevron-down"></i>}
                />
              </div>
            </div>

            {!isDataLoaded ? (
              <LoaderPage />
            ) : (
              <table id="crancy-table__main" className="crancy-table__main crancy-table__main-v3" >
                <thead className="crancy-table__head">
                  <tr>
                    <th className="crancy-table__column-1 crancy-table__h1">
                      <div className="crancy-wc__checkbox ">
                        <span className="">Created at </span>
                      </div>
                    </th>
                    <th className="crancy-table__column-2 crancy-table__h2">
                      <span>Name</span>
                    </th>
                    <th className="crancy-table__column-4 crancy-table__h4">
                      Pax
                    </th>
                    <th className="crancy-table__column-3 crancy-table__h3">
                      Location
                    </th>
                    <th className="crancy-table__column-4 crancy-table__h4">
                      Booking Date
                    </th>
                    <th className="crancy-table__column-4 crancy-table__h4">
                     Status
                    </th>
                    <th className="crancy-table__column-4 crancy-table__h4">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="crancy-table__body ">
                  {filteredLocations
                  .map((qry, index) => {
                    const current = page * show;
                    const previous = current - show;
                    if (
                      previous > 0 &&
                      index + 1 > previous &&
                      index + 1 <= current
                    ) {
                      return (
                        <QueryItem
                          queryy={qry}
                          key={index + "key"}
                          updateTask={setUpdateTask}
                        />
                      );
                    } else if (page === 1) {
                      return (
                        index < page * show && (
                          <QueryItem
                            queryy={qry}
                            key={index + "key"}
                            updateTask={setUpdateTask}
                          />
                        )
                      );
                    } else return null;
                  })}
                </tbody>
              </table>
            )}
            <div className="crancy-table-bottom">
              <div id="crancy-table__main_filter" className="dataTables_filter">
                <label>
                  Search:
                  <input
                    type="search"
                    id="loc-search"
                    className="form-control form-control-sm"
                    placeholder=""
                    aria-controls="crancy-table__main"
                  />
                </label>
              </div>
              <div
                className="dataTables_length"
                id="crancy-table__main_length"
                style={{ display: "flex" }}
              >
                <label
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <span> Show result: </span>
                  <select
                    name="crancy-table__main_length"
                    aria-controls="crancy-table__main"
                    className="form-select form-select-sm"
                    defaultValue={25}
                    onChange={(e) => setShow(e.target.value)}
                  >
                    <option value="4">4</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </select>
                </label>
              </div>
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="crancy-table__main_paginate"
              >
                <ul className="pagination">
                  <li
                    className={`paginate_button page-item previous ${
                      page === 1 ? "disabled" : ""
                    }`}
                    id="crancy-table__main_previous"
                    onClick={() => page > 1 && setPage(page - 1)}
                  >
                    <a
                      aria-controls="crancy-table__main"
                      data-dt-idx="previous"
                      tabIndex="0"
                      className="page-link"
                    >
                      <i className="fas fa-angle-left"></i>
                    </a>
                  </li>
                  {Array.from(
                    Array(Math.ceil(filteredLocations.length / show)).keys("n")
                  ).map((id, index) => (
                    <li
                      className={`paginate_button page-item ${
                        page === index + 1 ? "active" : ""
                      }`}
                      onClick={() => setPage(index + 1)}
                      key={index + "key"}
                    >
                      <a
                        aria-controls="crancy-table__main"
                        data-dt-idx="0"
                        tabIndex="0"
                        className="page-link"
                      >
                        {index + 1}
                      </a>
                    </li>
                  ))}
                  <li
                    className={`paginate_button page-item next ${
                      page === filteredLocations.length % show < 1 ? "disabled" : ""
                    }`}
                    id="crancy-table__main_next"
                    onClick={() =>
                      page < Math.ceil(filteredLocations.length / show) &&
                      setPage(page + 1)
                    }
                  >
                    <a
                      aria-controls="crancy-table__main"
                      data-dt-idx="next"
                      tabIndex="0"
                      className="page-link"
                    >
                      <i className="fas fa-angle-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingQueriesCom;
