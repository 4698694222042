import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { createHomeMetatags, fetchSingleHomeMetatags, updateHomeMetaTags, updateHomestay } from "../../crud/homestay.crud";
import { useSelector } from 'react-redux';
import { BlobServiceClient } from '@azure/storage-blob';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function HomestayImageUploads({ className }) {
    const history = useNavigate();
    const location = useLocation();
    const pathSegments = location.pathname
        .split("/")
        .filter((segment) => segment !== "");
    const homestaySlug = pathSegments.length > 0 ? pathSegments[1] : null;

    const HomestayInfo = useSelector((state) => state.selectedHomestay.homestayInfo);
    console.log("HomestayInfo", HomestayInfo.hs_pics);

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [imageUrls, setImageUrls] = useState(HomestayInfo.hs_pics || []);

    const [buttonClicked, setButtonClicked] = useState(false);

    console.log("imageUrls", imageUrls);

    // image upload
    const handleFileChange = (e) => {
        const selectedFiles = e.target.files;
        const selectedFilesArray = Array.from(selectedFiles);
        const imagesArray = selectedFilesArray.map((file) => {
            return file;
        })
        setSelectedFiles((previousImages) => previousImages.concat(imagesArray));
        // setSelectedFiles(e.target.files);
    };
    console.log("selectedFiles", selectedFiles);
    const handleUpload = async () => {
        // console.log("selectedFiles",selectedFiles);
        const connectionString = 'https://staywidus4wetrekkers.blob.core.windows.net/staywidus?sp=racwdli&st=2024-04-03T05:50:53Z&se=2024-12-01T13:50:53Z&spr=https&sv=2022-11-02&sr=c&sig=BGS6Z1YmK9Gd2YqVeN1whaZsoPOW8GdvbMAhWexNK0c%3D';
        const containerName = 'staywidus';
        const blobServiceClient = new BlobServiceClient(connectionString);
        const containerClient = blobServiceClient.getContainerClient(containerName);

        const urls = [...imageUrls];
        console.log("urls", urls);

        for (const file of selectedFiles) {
            const blobName = `${homestaySlug}/${file.name}`;
            const blockBlobClient = containerClient.getBlockBlobClient(blobName);

            try {
                await blockBlobClient.uploadBrowserData(file);
                console.log(`File ${blobName} uploaded successfully`);
                const url = `https://staywidus4wetrekkers.blob.core.windows.net/${containerName}/${containerName}/${blobName}`;
                urls.push(url);
            } catch (error) {
                console.error(`Error uploading file ${blobName}:`, error);
            }
        }

        setImageUrls(urls);
        setSelectedFiles([]);
    };


    const deleteUserImage = async (blobUrlToDelete) => {
        const connectionString = 'https://staywidus4wetrekkers.blob.core.windows.net/staywidus?sp=racwdli&st=2024-04-03T05:50:53Z&se=2024-12-01T13:50:53Z&spr=https&sv=2022-11-02&sr=c&sig=BGS6Z1YmK9Gd2YqVeN1whaZsoPOW8GdvbMAhWexNK0c%3D';
        const containerName = 'staywidus';
        const blobServiceClient = new BlobServiceClient(connectionString);
        const containerClient = blobServiceClient.getContainerClient(containerName);

        try {
            console.log("blobUrlToDelete", blobUrlToDelete);
            // Extract blob name from URL
            const urlParts = blobUrlToDelete.split('/');
            const foldername = urlParts[urlParts.length - 2];
            const fileName = urlParts[urlParts.length - 1];
            const blobName = `${foldername}/${fileName}`;
            console.log("blobName", blobName);
            // Delete the blob
            await containerClient.deleteBlob(blobName);
            console.log(`Blob ${blobName} deleted successfully`);

            // Update image URLs state
            setImageUrls(imageUrls.filter(url => url !== blobUrlToDelete));
        } catch (error) {
            console.error('Error deleting blob:', error.message);
        }
    };

    // const demoHandel = (e) => {
    //     toast.success('Updated successfully!', {
    //         position: toast.POSITION.TOP_CENTER, // Adjust position here
    //         style: {
    //             width: '500px' // Adjust width here
    //         }
    //     });
    // }
    const updateUserSubmit = (e) => {
        e.preventDefault();
        // setIsSubmitting(true);
        const updatedHomestayDetails = { ...HomestayInfo };
        updatedHomestayDetails.hs_pics = imageUrls;
        console.log("image Update", updatedHomestayDetails);
        updateHomestay(homestaySlug, updatedHomestayDetails)
            .then((res) => {
                // window.location.reload();
                toast.success('Updated successfully!', {
                    position: toast.POSITION.TOP_CENTER, // Adjust position here
                    style: {
                        width: '500px' // Adjust width here
                    }
                });
            })
            .catch((err) => {
                console.log(err);
            });
        setButtonClicked(true);
        setTimeout(() => {
            setButtonClicked(false);
        }, 8000);
    };


    return (
        <>
            <div
                className={
                    className ? className : "col-xxl-9 col-12 crancy-main__column"
                }
            >
                <div className="crancy-body">
                    <div className="crancy-dsinner">
                        <div className="crancy__item-form--group mg-top-form-20">
                            <label className="crancy__item-label">Picture</label>
                            <input
                                className=""
                                type="file"
                                name="pics"
                                id="pics"

                                onChange={handleFileChange}
                                placeholder="Pictures"
                                multiple
                            />
                        </div>
                        <div className="row justify-content-end">
                            <div className="col-4">
                                <button
                                    className={`btn my-2 ${selectedFiles.length >= 1 ? 'btn-success' : 'btn-secondary'} `}
                                    onClick={handleUpload}
                                    disabled={!selectedFiles.length ? true : false}
                                    style={{ width: "100px" }}
                                >
                                    Upload
                                </button>

                                <button
                                    className="btn btn-success ms-2"
                                    onClick={updateUserSubmit}
                                    style={{ width: "100px" }}
                                >
                                    Update
                                </button>
                            </div>
                        </div>


                    </div>
                    <div className="images">
                        {imageUrls && imageUrls.map((image, index) => {
                            const urlParts = image.split('/');
                            const filename = urlParts[urlParts.length - 1];
                            return (
                                <div key={index} className="image">
                                    <div>
                                        <span >
                                            {filename}
                                        </span>
                                    </div>
                                    <img src={image} height={200} />
                                    <button onClick={() => deleteUserImage(image)}>Delete Image</button>

                                </div>

                            )
                        })}
                    </div>
                </div>

            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            // transition: "Bounce"
            />
        </>
    );
}

export default HomestayImageUploads;
