import React from "react";
import ProgressCom from "../../component/home/ProgressCom";
// import SalesCharts from "../../component/home/SalesCharts";
// import AnalyticsConversions from "../../component/home/AnalyticsConversions";
import Sidebar from "../../component/home/Sidebar";
import Layout from "../../component/home/Layout";
import BodyWrapper from "../../component/home/BodyWrapper";
import useMenu from "../../hooks/useMenu";
// import { useSelector } from 'react-redux';

function Home() {
  // const ownerDtl = useSelector(state => state.selectedOwner.ownerInfo );
  // const homestayDlt = useSelector((state) => state.selectedHomestay.homestayInfo);
  // home
  useMenu();
  return (
    <Layout>
      <BodyWrapper>
        <ProgressCom />
      </BodyWrapper>
      <Sidebar />
    </Layout>
  );
}

export default Home;
