import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import LoaderPage from "../templates/LoaderPage";
import { fetchSingleHomestay, updateHomestay } from "../../crud/homestay.crud";
import { fetchAllLocations } from "../../crud/location.crud";
import { fetchHomestayOwner, fetchSingleOwner } from "../../crud/homestayowner.crud";
import { fetchAllTags } from "../../crud/tag.crud";
import { useDispatch } from "react-redux";
import { storeHomestay } from "../../redux/features/currentHomestaySlice";
import SelectBox from "../form/SelectBox";
import MultiSelectBox from "../form/MultiSelectBox";
import Select from "react-select";
import { BlobServiceClient } from '@azure/storage-blob';


function LocationTagsCom({ className }) {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [homestayDetils, setHomestayDetils] = useState([]);
  // const [checkDetils, setCheckDetils] = useState([{ hs_pincode: '', hs_extraInfo: { spaceOffered: '' } }]);
  const [allLocation, setAllLocation] = useState([]);
  const [locInput, setLocInput] = useState([]);
  const [idd, setIdd] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [oriLocs, setOriLocs] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();
  const [activeAccordion, setActiveAccordion] = useState(1);
  const [hsTags, setHsTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [owner, setOwner] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState(null);

  // console.log("homestayDetils", homestayDetils);
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");
  const homestaySlug = pathSegments.length > 0 ? pathSegments[1] : null;
  const ownerID = homestayDetils[0]?.hs_ownedBy;
  const last_number = homestaySlug.slice(-13);
  useEffect(() => {
    fetchSingleOwner(ownerID).then((res) => {
      setOwnerName(res.data[0]?.name);
      setIsDataLoaded(true);
    });
    fetchSingleHomestay(homestaySlug).then((res) => {
      setHomestayDetils(res.data);
      dispatch(storeHomestay(res.data[0]));
      setIsDataLoaded(true);
    });
  }, [homestaySlug, ownerID, dispatch]);
  useEffect(() => {
    fetchAllLocations().then((res) => {
      let arr = [];
      res.data.map((qwe) => {
        let obj = {
          label: qwe.city_vil,
          value: qwe.city_vil,
        };
        arr.push(obj);
        return true;
      });
      setOriLocs(res.data);
      setAllLocation(arr);
    });
  }, []);
  useEffect(() => {
    fetchAllTags().then((res) => {
      let arr = [];
      let ltgs = res.data.filter((tgg) => tgg.tagType === "homestay");
      ltgs.map((asd) => {
        let obj = {
          label: asd.tagName,
          value: asd.tagName,
        };
        arr.push(obj);
        return true;
      });
      setHsTags(arr);
    });
    fetchHomestayOwner().then((res) => {
      let arr = [];
      res.data.map((qwe) => {
        let obj = {
          label: qwe.name,
          value: qwe._id,
        };
        arr.push(obj);
        return true;
      });
      setOwner(arr);
    });
  }, []);
  useEffect(() => {
    if (homestayDetils && homestayDetils.length > 0) {
      if (homestayDetils[0].tags) {
        setSelectedTags(
          homestayDetils[0].tags.map((tag) => ({ label: tag, value: tag }))
        );
      } else {
        setSelectedTags([]);
      }
    } else {
      setSelectedTags([]);
    }
  }, [homestayDetils]);

  const handleChangeSelect = (val, type) => {
    if (type === "loc") {
      const selectedLocation = val ? val.value : null;
      let nObj = {};
      for (const lc of oriLocs) {
        if (lc.city_vil === selectedLocation) {
          nObj = {
            city_vil: lc.city_vil,
            locSlug: lc.locSlug,
            district: lc.district,
            state: lc.state,
          };
          break;
        }
      }
      let hsSlug = nObj['locSlug'] + Date.now();
      if (Object.keys(nObj).length > 0) {
        // Update homestay details only if a matching location is found
        setHomestayDetils([{ ...homestayDetils[0], hs_location: nObj, 'homestay_slug': hsSlug }]);
      }
      let localObj = [...locInput];
      const ChangedVal = !val;
      const newObj = {
        "id": idd,
        "obj": {
          'locationActive': ChangedVal,
        },
      };

      const existingIndex = localObj.findIndex(obj => obj.id === idd);

      if (existingIndex !== -1) {
        localObj[existingIndex] = newObj;
      } else {
        localObj.push(newObj);
      }

      setLocInput(localObj);
    } else if (type === "tag") {
      const arrtg = val.map((vv) => vv.label);
      setHomestayDetils([{ ...homestayDetils[0], tags: arrtg }]);
      setSelectedTags(val); // Update selectedTags with the new values
    } else if (type === "own") {
      // Handle "own" type
      const selectedOwner = val ? val.value : null;
      setSelectedOwner(selectedOwner);
      // Assuming you have a state variable for homestay details and you want to update hs_ownedBy
      setHomestayDetils([{ ...homestayDetils[0], hs_ownedBy: selectedOwner }]);

    }
  };



  const handleChange = (e, index) => {
    const name = e.target.name;
    const val = e.target.value;

    setHomestayDetils((prevDetails) => {
      const updatedDetails = [...prevDetails];
      const updatedHomestay = { ...updatedDetails[index] };

      switch (name) {
        case "name":
          updatedHomestay.hs_name = val;
          break;
        case "hs_address":
          updatedHomestay.hs_address = val;
          break;
        case "hs_pincode":
          updatedHomestay.hs_pincode = val;
          break;
        case "hs_contact":
          updatedHomestay.hs_contact = val;
          break;
        case "hs_email":
          updatedHomestay.hs_email = val;
          break;
        case "hs_price":
          updatedHomestay.hs_price = val;
          break;
        case "hs_discount":
          updatedHomestay.hs_discount = val;
          break;
        case "hs_roomCount":
          updatedHomestay.hs_roomCount = val;
          break;
        case "kitchenAvailability":
          updatedHomestay.hs_extraInfo = {
            ...updatedHomestay.hs_extraInfo,
            kitchenAvailability: val,
          };
          break;
        case "spaceOffered":
          updatedHomestay.hs_extraInfo = {
            ...updatedHomestay.hs_extraInfo,
            spaceOffered: val,
          };
          break;
        case "checkinTime":
          updatedHomestay.hs_extraInfo = {
            ...updatedHomestay.hs_extraInfo,
            checkinTime: val,
          };
          break;
        case "checkoutTime":
          updatedHomestay.hs_extraInfo = {
            ...updatedHomestay.hs_extraInfo,
            checkoutTime: val,
          };
          break;
        case "petAllowed":
          updatedHomestay.hs_extraInfo = {
            ...updatedHomestay.hs_extraInfo,
            petAllowed: val,
          };
          break;
        case "checkinRequired":
          updatedHomestay.hs_extraInfo = {
            ...updatedHomestay.hs_extraInfo,
            checkinRequired: val,
          };
          break;
        case "cancellationPolicy":
          updatedHomestay.hs_extraInfo = {
            ...updatedHomestay.hs_extraInfo,
            cancellationPolicy: val,
          };
          break;
        case "acAvailability":
          updatedHomestay.hs_extraInfo = {
            ...updatedHomestay.hs_extraInfo,
            acAvailability: val,
          };
          break;
        case "parking":
          updatedHomestay.hs_extraInfo = {
            ...updatedHomestay.hs_extraInfo,
            parking: val,
          };
          break;
        case "mountainView":
          updatedHomestay.hs_extraInfo = {
            ...updatedHomestay.hs_extraInfo,
            mountainView: val,
          };
          break;
        default:
          break;
      }

      updatedDetails[index] = updatedHomestay;
      return updatedDetails;
    });
  };
  


  const updateUserSubmit = (e) => {
    e.preventDefault();
    // setIsSubmitting(true);
    const updatedHomestayDetails ={ ...homestayDetils[0]};
    // updatedHomestayDetails.hs_pics = imageUrls;
    updateHomestay(homestaySlug, updatedHomestayDetails)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
    setButtonClicked(true);
    setTimeout(() => {
      setButtonClicked(false);
    }, 8000);
  };
  return (
    <div
      className={className ? className : "col-xxl-9 col-12 crancy-main__column"}
    >
      <div className="crancy-body">
        <div className="col-12">
          <div
            className="crancy-accordion accordion accordion-flush"
            id="crancy-accordion"
          >
            <div
              className={`accordion-item crancy-accordion__single ${activeAccordion === 1 ? "active" : ""
                }`}
            >
              <h2 className="accordion-header">
                <button
                  className="accordion-button crancy-accordion__heading"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#accordionContent"
                  aria-expanded={activeAccordion === 1}
                  aria-controls="accordionContent"
                  onClick={() => setActiveAccordion(1)}
                >
                  Basic Info
                </button>
              </h2>
              <div
                id="accordionContent"
                className={`accordion-collapse collapse ${activeAccordion === 1 ? "show" : ""
                  }`}
                aria-labelledby="accordionHeader"
                data-bs-parent="#crancy-accordion"
              >
                <div
                  className="accordion-body crancy-accordion__body"
                // key={index}
                >
                  <div className="crancy__item-form--group">
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <div className="crancy__item-form--group mg-top-form-20">
                          <label className="crancy__item-label">
                            Homestay Name
                          </label>
                          <input
                            className="crancy__item-input"
                            type="text"
                            name="name"
                            value={homestayDetils[0]?.hs_name}
                            onChange={(e) => handleChange(e, 0)}
                            required="required"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="crancy__item-form--group mg-top-form-20">
                          <label className="crancy__item-label">Address</label>
                          <input
                            className="crancy__item-input"
                            type="text"
                            name="hs_address"
                            value={homestayDetils[0]?.hs_address}
                            onChange={(e) => handleChange(e, 0)}
                            required="required"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <div className="crancy__item-form--group mg-top-form-20">
                          <label className="crancy__item-label">Location</label>
                          <Select
                            className="mb-1 col-12"
                            placeholder="Select Location"
                            closeMenuOnSelect={true}
                            name="location"
                            id="location"
                            onChange={(newValue) =>
                              handleChangeSelect(newValue, "loc")
                            }
                            options={allLocation}
                            value={allLocation.find((option) => option.value === homestayDetils[0]?.hs_location?.city_vil)}
                            isDisabled
                          />
                          <span style={{ fontSize: "12px" }}>Can't change Location, If you Want to Change Location then create a <Link>New Homestay</Link> and delete this Homestay</span>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="crancy__item-form--group mg-top-form-20">
                          <label className="crancy__item-label">PinCode</label>
                          <input
                            className="crancy__item-input"
                            type="text"
                            name="hs_pincode"
                            value={homestayDetils[0]?.hs_pincode}
                            onChange={(e) => handleChange(e, 0)}
                            required="required"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <div className="crancy__item-form--group mg-top-form-20">
                          <label className="crancy__item-label">
                            Homestay Contact
                          </label>
                          <input
                            className="crancy__item-input"
                            type="text"
                            name="hs_contact"
                            value={homestayDetils[0]?.hs_contact}
                            onChange={(e) => handleChange(e, 0)}
                          // required="required"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="crancy__item-form--group mg-top-form-20">
                          <label className="crancy__item-label">
                            Homestay Email
                          </label>
                          <input
                            className="crancy__item-input"
                            type="text"
                            name="hs_email"
                            value={homestayDetils[0]?.hs_email}
                            onChange={(e) => handleChange(e, 0)}
                          // required="required"
                          />
                        </div>
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <div className="crancy__item-form--group mg-top-form-20">
                          <label className="crancy__item-label">Price</label>
                          <input
                            className="crancy__item-input"
                            type="number"
                            name="hs_price"
                            value={homestayDetils[0]?.hs_price}
                            onChange={(e) => handleChange(e, 0)}
                            required="required"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="crancy__item-form--group mg-top-form-20">
                          <label className="crancy__item-label">Discount</label>
                          <input
                            className="crancy__item-input"
                            type="number"
                            name="hs_discount"
                            value={homestayDetils[0]?.hs_discount}
                            onChange={(e) => handleChange(e, 0)}
                            required="required"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <div className="crancy__item-form--group mg-top-form-20">
                          <label className="crancy__item-label">Rooms</label>
                          <input
                            className="crancy__item-input"
                            type="number"
                            name="hs_roomCount"
                            value={homestayDetils[0]?.hs_roomCount}
                            onChange={(e) => handleChange(e, 0)}
                            required="required"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="crancy__item-form--group mg-top-form-20">
                          <label className="crancy__item-label">
                            Owner Name
                          </label>
                          <Select
                            className="mb-1 col-12"
                            placeholder="Select Owner"
                            closeMenuOnSelect={true}
                            name="owner"
                            id="owner"
                            value={owner.find((option) => option.value === homestayDetils[0]?.hs_ownedBy)}
                            onChange={(newValue) =>
                              handleChangeSelect(newValue, "own")
                            }
                            options={owner}
                            isDisabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`accordion-item crancy-accordion__single ${activeAccordion === 2 ? "active" : ""
                }`}
            >
              <h2 className="accordion-header">
                <button
                  className="accordion-button crancy-accordion__heading"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#accordionContent2"
                  aria-expanded={activeAccordion === 2}
                  aria-controls="accordionContent2"
                  onClick={() => setActiveAccordion(2)}
                >
                  Extra Info
                </button>
              </h2>
              <div
                id="accordionContent2"
                className={`accordion-collapse collapse ${activeAccordion === 2 ? "show" : ""
                  }`}
                aria-labelledby="accordionHeader"
                data-bs-parent="#crancy-accordion"
              >
                <div className="accordion-body crancy-accordion__body">
                  <div className="crancy__item-form--group">
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <div className="crancy__item-form--group mg-top-form-20">
                          <label className="crancy__item-label">
                            Kitchen Availability
                          </label>
                          <input
                            className="crancy__item-input"
                            type="text"
                            name="kitchenAvailability"
                            value={
                              homestayDetils[0]?.hs_extraInfo
                                ?.kitchenAvailability
                            }
                            onChange={(e) => handleChange(e, 0)}
                            required="required"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="crancy__item-form--group mg-top-form-20">
                          <label className="crancy__item-label">
                            Space Offered
                          </label>
                          <input
                            className="crancy__item-input"
                            type="text"
                            name="spaceOffered"
                            value={
                              homestayDetils[0]?.hs_extraInfo?.spaceOffered ||
                              ""
                            }
                            onChange={(e) => handleChange(e, 0)}
                            required="required"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <div className="crancy__item-form--group mg-top-form-20">
                          <label className="crancy__item-label">
                            Checkin Time
                          </label>
                          <input
                            className="crancy__item-input"
                            type="text"
                            name="checkinTime"
                            value={homestayDetils[0]?.hs_extraInfo?.checkinTime}
                            onChange={(e) => handleChange(e, 0)}
                            required="required"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="crancy__item-form--group mg-top-form-20">
                          <label className="crancy__item-label">
                            Checkout Time
                          </label>
                          <input
                            className="crancy__item-input"
                            type="text"
                            name="checkoutTime"
                            value={
                              homestayDetils[0]?.hs_extraInfo?.checkoutTime
                            }
                            onChange={(e) => handleChange(e, 0)}
                            required="required"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <div className="crancy__item-form--group mg-top-form-20">
                          <label className="crancy__item-label">
                            Pet Allowed
                          </label>
                          <input
                            className="crancy__item-input"
                            type="text"
                            name="petAllowed"
                            value={homestayDetils[0]?.hs_extraInfo?.petAllowed}
                            onChange={(e) => handleChange(e, 0)}
                            required="required"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="crancy__item-form--group mg-top-form-20">
                          <label className="crancy__item-label">
                            Checkin Required
                          </label>
                          <input
                            className="crancy__item-input"
                            type="text"
                            name="checkinRequired"
                            value={
                              homestayDetils[0]?.hs_extraInfo?.checkinRequired
                            }
                            onChange={(e) => handleChange(e, 0)}
                            required="required"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <div className="crancy__item-form--group mg-top-form-20">
                          <label className="crancy__item-label">
                            Cancellation Policy
                          </label>
                          <input
                            className="crancy__item-input"
                            type="text"
                            name="cancellationPolicy"
                            value={
                              homestayDetils[0]?.hs_extraInfo
                                ?.cancellationPolicy
                            }
                            onChange={(e) => handleChange(e, 0)}
                            required="required"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="crancy__item-form--group mg-top-form-20">
                          <label className="crancy__item-label">
                            AC Availability
                          </label>
                          <input
                            className="crancy__item-input"
                            type="text"
                            name="acAvailability"
                            value={
                              homestayDetils[0]?.hs_extraInfo?.acAvailability
                            }
                            onChange={(e) => handleChange(e, 0)}
                            required="required"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <div className="crancy__item-form--group mg-top-form-20">
                          <label className="crancy__item-label">Parking</label>
                          <input
                            className="crancy__item-input"
                            type="text"
                            name="parking"
                            value={homestayDetils[0]?.hs_extraInfo?.parking}
                            onChange={(e) => handleChange(e, 0)}
                            required="required"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="crancy__item-form--group mg-top-form-20">
                          <label className="crancy__item-label">
                            Mountain View
                          </label>
                          <input
                            className="crancy__item-input"
                            type="text"
                            name="mountainView"
                            value={
                              homestayDetils[0]?.hs_extraInfo?.mountainView
                            }
                            onChange={(e) => handleChange(e, 0)}
                            required="required"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`accordion-item crancy-accordion__single ${activeAccordion === 2 ? "active" : ""
                }`}
            >
              <h2 className="accordion-header">
                <button
                  className="accordion-button crancy-accordion__heading"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#accordionContent3"
                  aria-expanded={activeAccordion === 3}
                  aria-controls="accordionContent3"
                  onClick={() => setActiveAccordion(3)}
                >
                  Tags
                </button>
              </h2>
              <div
                id="accordionContent3"
                className={`accordion-collapse collapse ${activeAccordion === 3 ? "show" : ""
                  }`}
                aria-labelledby="accordionHeader"
                data-bs-parent="#crancy-accordion"
              >
                <div className="accordion-body crancy-accordion__body">
                  <div className="crancy__item-form--group">
                    <div className="row">
                      <div className=" col-12">
                        <div className="crancy__item-form--group mg-top-form-20">
                          <label className="crancy__item-label">Tags</label>
                          <Select
                            closeMenuOnSelect={false}
                            placeholder="Select Tags"
                            // components={animatedComponents}
                            name="tags"
                            id="homestay-tag"
                            onChange={(newValue) =>
                              handleChangeSelect(newValue, "tag")
                            }
                            isMulti
                            options={hsTags}
                            value={selectedTags}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          className="crancy-btn crancy-color8__bg"
          type="submit"
          onClick={updateUserSubmit}
          disabled={buttonClicked}
          style={{ cursor: buttonClicked ? 'not-allowed' : 'pointer' }}
        >
          <svg
            width="26"
            height="30"
            viewBox="0 0 26 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.2484 25.8438C18.5393 25.8472 23.4162 21.5133 24.045 16.2064C24.559 11.8667 23.0512 8.36438 19.6548 5.6833C19.0487 5.20482 18.9054 4.70219 19.2659 4.22716C19.6172 3.76249 20.1767 3.73373 20.7634 4.20071C25.0411 7.61331 26.7672 12.0576 25.6858 17.452C24.6613 22.5657 20.4371 26.5753 15.443 27.4793C14.7153 27.6116 13.9841 27.699 13.2439 27.7197C13.2462 27.094 13.2473 26.4683 13.2484 25.8438Z"
              fill="white"
            />
            <path
              d="M12.7651 3.59243C7.91199 3.64879 3.17832 7.14075 2.11629 12.3707C1.17592 17.0048 2.66891 20.8142 6.34623 23.7379C6.69304 24.014 6.97618 24.2935 6.90454 24.7812C6.79652 25.5127 6.02671 25.7956 5.37289 25.3252C3.90151 24.2682 2.70757 22.9455 1.78767 21.3755C-2.76862 13.6025 1.81951 3.52227 10.6285 1.93961C11.3392 1.81194 12.0521 1.74063 12.7707 1.69922C12.7685 2.33067 12.7662 2.96212 12.7651 3.59243Z"
              fill="white"
            />
            <path
              d="M19.2544 11.9937C19.2647 12.5941 19.0702 12.976 18.7177 13.2716C16.4049 15.2166 14.0989 17.1696 11.7679 19.0927C10.9435 19.7725 9.85758 19.6183 9.19921 18.7695C8.46352 17.8217 7.75171 16.8544 7.05354 15.8768C6.54526 15.1648 6.68058 14.2757 7.33213 13.7857C7.99732 13.2854 8.8308 13.4039 9.39252 14.1078C9.79618 14.6139 10.1885 15.1314 10.5489 15.6686C10.7661 15.9941 10.9139 15.978 11.1982 15.7364C12.9345 14.2573 14.6868 12.7977 16.4356 11.3335C16.6824 11.1265 16.9143 10.8976 17.2156 10.7665C18.1776 10.3455 19.251 11.0575 19.2544 11.9937Z"
              fill="white"
            />
            <path
              d="M13.2483 25.8439C13.2472 26.4696 13.2449 27.0953 13.2437 27.7199C13.2415 28.1408 13.2517 28.5629 13.2335 28.9839C13.2142 29.4198 13.005 29.5429 12.6525 29.3037C11.5836 28.5802 10.525 27.8395 9.46523 27.0999C9.173 26.8963 9.17186 26.6663 9.46409 26.4616C10.5238 25.7231 11.5813 24.9813 12.6513 24.259C13.0061 24.0197 13.213 24.1428 13.2335 24.5799C13.2528 25.0008 13.2449 25.4218 13.2483 25.8439Z"
              fill="white"
            />
            <path
              d="M12.7651 3.59318C12.7663 2.96288 12.7685 2.33143 12.7697 1.70113C12.7708 1.26176 12.7572 0.821235 12.7765 0.381863C12.7924 0.0115033 12.9982 -0.105816 13.3064 0.102368C14.3889 0.836187 15.4646 1.58266 16.5391 2.32913C16.8325 2.53271 16.8313 2.7639 16.5402 2.96633C15.4657 3.71395 14.3923 4.46158 13.3075 5.19424C13.005 5.39783 12.7924 5.28511 12.7765 4.91245C12.7572 4.47423 12.7674 4.03371 12.7651 3.59318Z"
              fill="white"
            />
          </svg>
          Update Profile
        </button>
      </div>
    </div>
  );
}

export default LocationTagsCom;
