import axios from './axios.interceptor';
import api from './api.util';

export const QUERY = `${api}/query`;

export async function fetchAllQueries() {
    const qryUL = `${QUERY}/showAllbookingQuery`;
    const asd = await axios.get(qryUL, {
        cors: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type"
        }
    });
    return asd;
}
export async function fetchSingleQueries(id) {
    const hsUL = `${QUERY}/${id}`;
    const asd = await axios.get(hsUL, {
        cors: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type"
        }
    });
    return asd;
}
export async function updateQueries(id,obj) {
    const usrUL = `${QUERY}/update/${id}`;
    return axios.put(usrUL, obj);
}