import React, { useState } from "react";
import toggleIcon from "../../assets/img/toggle-icon.svg";
import { Link } from "react-router-dom";
import { deleteHomestay } from "../../crud/homestay.crud";
import DeleteCom from "../homestayDetails/DeleteCom";

function HomestayCard({ homestay }) {
  const {
    hs_name,
    homestay_slug,
    hs_address,
    hs_pincode,
    hs_contact,
    hs_email,
    hs_location,
    hs_price,
    hs_discount,
    hs_roomCount,
    homestayHitCount
  } = homestay;
  const [isDeleteLocationOpen, setIsDeleteLocationOpen] = useState(false);
  

  const deleteModal = (isOpen) =>{
    setIsDeleteLocationOpen(isOpen);
  }
  return (
    <>
    <div className="col-md-4 col-12">
      <div className="crancy-single-integration mg-top-30">
        <div className="crancy-toggle">
          
          <Link to={`/homestays/${homestay_slug}`}>
            <i className="fa-solid fa-info-circle mx-1" aria-hidden="true"></i>
          </Link>
          <Link onClick={() => deleteModal(true)} className="ms-1">
            <i className="fa-solid fa-trash text-danger"></i>
          </Link>
        </div>
        <div className="crancy-single-integration__head">
          <div className="crancy-single-integration__heading">
          <Link to={`/homestays/${homestay_slug}`}>
            <h4
              className="crancy-single-integration__title"
              style={{ color: "#0a82fd" }}
            >
              {hs_name}
            </h4>
            </Link>
            <p className="crancy-single-integration__label">
              {hs_location.city_vil}
            </p>
          </div>
        </div>
        <div className="crancy-single-integration__content">
          <p>
            <span>Price : </span>
            <span style={{ border: "1px solid #cdcdcd", padding: "2px" }}>
              {hs_price}
            </span>{" "}
            <span>Discount :</span> {hs_discount}
          </p>
          <p>
            <span>Total Rooms :</span> {hs_roomCount}
          </p>
          <p>
            <span>Total Clicked :</span> {homestayHitCount}
          </p>
        </div>
      </div>
    </div>
    {isDeleteLocationOpen && (
        <DeleteCom
          slug={homestay_slug}
          show={isDeleteLocationOpen}
          handleShow={deleteModal}
        />
      )}
    </>
  );
}

export default HomestayCard;
