import React from "react";
import OwnerDash from "../cards/OwnerDash";
import TopHomestayDash from "../cards/TopHomestayDash";
import CalenderSm from "../cards/CalenderSm";

function Sidebar() {
  return (
    <div className="col-xxl-3 col-12 crancy-main__sidebar">
      <div className="crancy-sidebar mg-top-30">
        <div className="row mb-2">
          <CalenderSm />
        </div>
        <div className="row">
          <OwnerDash />
          <TopHomestayDash />
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
