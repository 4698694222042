import React, { useState } from "react";
// import BreadCrumb from "../../component/home-two/BreadCrumb";
import Layout from "../../component/home-two/Layout";
import InnerWrapper from "../../component/home-three/InnerWrapper";
import useMenu from "../../hooks/useMenu";
import OwnersCom from "../../component/owners/OwnersCom";
import AddNewOwner from "../../component/owners/AddNewOwner";

function Locations() {
  useMenu();
  const [addTask, setAddTask] = useState(false);
  return (
    <Layout>
      <div className="row">
        <InnerWrapper>
          <AddNewOwner show={addTask} handleShow={setAddTask} />
          <OwnersCom addTask={setAddTask}/>
        </InnerWrapper>
      </div>
    </Layout>
  );
}

export default Locations;
