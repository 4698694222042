import axios from './axios.interceptor';
import api from './api.util';

export const Homestay = `${api}/homestay`;


export async function fetchAllHomestays() {
    const asd = await axios.get(Homestay, {
        cors: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type"
        }
    });
    return asd;
}
export async function fetchSingleHomestay(slug) {
    const hsUL = `${Homestay}/${slug}`;
    const asd = await axios.get(hsUL, {
        cors: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type"
        }
    });
    return asd;
}

export async function fetchHomestaysUnderOwner(ownerid) {
    const usrUL = `${Homestay}/owner/${ownerid}`;
    const asd = await axios.get(usrUL, {
        cors: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type"
        }
    });
    return asd;
}
export async function createHomestay(obj) {
    const asd = await axios.post(Homestay, obj, {
        cors: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type"
        }
    });
    return asd;
}

export async function updateHomestay(slug,obj) {
    const usrUL = `${Homestay}/${slug}`;
    return axios.put(usrUL, obj);
}

export async function deleteHomestay(slug) {
    const usrUL = `${Homestay}/${slug}`;
    return axios.delete(usrUL);
}
export async function fetchSingleHomeMetatags( slug ) {
    const url = `${Homestay}/metatags/${slug}`
    const asd = await axios.get(url, {
      cors: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type"
      }
    });
    return asd;
  }
  export function createHomeMetatags( obj ) {
    const url = `${Homestay}/metatags`
    const asd = axios.post(url, obj, {
      cors: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type"
      }
    });
    return asd;
  }
  export async function updateHomeMetaTags(slug,obj) {
    const usrUL = `${Homestay}/metatags/${slug}`;
    return axios.put(usrUL, obj, {
      cors: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type"
      }
    });
  }