import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import arrowIcon from "../../../assets/img/arrow-icon.svg";

function Menu({ toggleMenu, menu }) {
  const [dropdown, setDropdown] = useState(false);
  const location = useLocation();
  const handleDropdown = (name) => {
    setDropdown(name === dropdown ? "" : name);
  };
  return (
    <div className={`crancy-smenu ${menu && "crancy-close"}`} id="CrancyMenu">
      <div className={`admin-menu ${dropdown ? "no-overflow" : ""}`}>
        <div className="logo crancy-sidebar-padding pd-right-0">
          <Link className="crancy-logo" to="/">
            <h3>HS Admin</h3>
          </Link>
          <div
            id="crancy__sicon"
            className="crancy__sicon close-icon"
            onClick={toggleMenu}
          >
            <img src={arrowIcon} alt="arrow icon"/>
          </div>
        </div>
        
        <div className="admin-menu__one crancy-sidebar-padding mg-top-20">
          <h4 className="admin-menu__title">Menu</h4>
          <div className="menu-bar">
            <ul id="CrancyMenu" className="menu-bar__one crancy-dashboard-menu">
              
              <li
                className={location.pathname === "/" ? "active" : ""}
              >
                <Link className="collapsed" to="/">
                  <span className="menu-bar__text">
                    <span className="crancy-menu-icon crancy-svg-icon__v1">
                   
                    </span>
                    <span className="menu-bar__name">Dashboards</span>
                  </span>
                </Link>
              </li>
              <li
                className={location.pathname === "/locations" ? "active" : ""}
              >
                <Link className="collapsed" to="/locations">
                  <span className="menu-bar__text">
                    <span className="crancy-menu-icon crancy-svg-icon__v1">
                      
                    </span>
                    <span className="menu-bar__name">Locations</span>
                  </span>
                </Link>
              </li>
              <li
                className={location.pathname === "/homestays" ? "active" : ""}
              >
                <Link className="collapsed" to="/homestays">
                  <span className="menu-bar__text">
                    <span className="crancy-menu-icon crancy-svg-icon__v1">
                     
                    </span>
                    <span className="menu-bar__name">Homestays</span>
                  </span>
                </Link>
              </li>
              <li
                className={location.pathname === "/booking-queries" ? "active" : ""}
              >
                <Link className="collapsed" to="/booking-queries">
                  <span className="menu-bar__text">
                    <span className="crancy-menu-icon crancy-svg-icon__v1">
                     
                    </span>
                    <span className="menu-bar__name">Booking Queries</span>
                  </span>
                </Link>
              </li>
              <li
                className={location.pathname === "/tags" ? "active" : ""}
              >
                <Link className="collapsed" to="/tags">
                  <span className="menu-bar__text">
                    <span className="crancy-menu-icon crancy-svg-icon__v1">
                      
                    </span>
                    <span className="menu-bar__name">Tags</span>
                  </span>
                </Link>
              </li>
              <li
                className={location.pathname === "/owners" ? "active" : ""}
              >
                <Link className="collapsed" to="/owners">
                  <span className="menu-bar__text">
                    <span className="crancy-menu-icon crancy-svg-icon__v1">
                     
                    </span>
                    <span className="menu-bar__name">Owners</span>
                  </span>
                </Link>
              </li>
              <li
                className={location.pathname === "/Users" ? "active" : ""}
              >
                <Link className="collapsed" to="/Users">
                  <span className="menu-bar__text">
                    <span className="crancy-menu-icon crancy-svg-icon__v1">
                     
                    </span>
                    <span className="menu-bar__name">Users</span>
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="crancy-sidebar-padding pd-btm-40">
        </div>
      </div>
    </div>
  );
}

export default Menu;
