import React,{useState} from "react";
import googleLogo from "../../assets/img/google-logo.png";
import appleLogo from "../../assets/img/apple-logo.png";
import {login} from "../../crud/auth.crud";
import { Link } from "react-router-dom";
import {useNavigate} from "react-router-dom";
function LoginForm() {
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({email:"",password:""});

  const handleChange = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit =  (e) => {
    const email = loginData.email;
    const password =loginData.password;
    e.preventDefault();
    login(loginData)
      .then((res) => {
        if (res.status === 200) {
          const userLogin = res.data.loginDtl;
          const token = res.data.loginDtl.token;
          localStorage.setItem('user', JSON.stringify(userLogin));
          localStorage.setItem('token', JSON.stringify(token));
          navigate("/");
        }
        
        const userLogin = res.data.loginDtl;
      })
      .catch((err) => {
        console.log("error",err);
      });
    
  };
  return (
    <>
      <div className="crancy-wc__heading">
        <h3 className="crancy-wc__form-title crancy-wc__form-title__one">
          Login to your account
        </h3>
      </div>
      <form className="crancy-wc__form-main">
        <div className="form-group">
          <div className="form-group__input">
            <input
              className="crancy-wc__form-input"
              type="email"
              name="email"
              placeholder="admin@mail.com"
              onChange={handleChange}
              required="required"
            />
          </div>
        </div>
        
        <div className="form-group">
          <div className="form-group__input">
            <input
              className="crancy-wc__form-input"
              placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
              id="password-field"
              type="password"
              name="password"
              onChange={handleChange}
              // maxLength="8"
              required="required"
            />
            <span className="crancy-wc__toggle">
              <i className="fas fa-eye" id="toggle-icon"></i>
            </span>
          </div>
        </div>
        
        <div className="form-group form-mg-top25">
          <div className="crancy-wc__button">
            <button className="ntfmax-wc__btn" type="submit" onClick={handleSubmit}>
              Sign in with email
            </button>
          </div>
          
         
        </div>
        
      </form>
    </>
  );
}

export default LoginForm;
