import axios from './axios.interceptor';
import api from './api.util';

export const LOGIN_URL = `${api}/users/login`;

export function login(obj) {
    return axios.post(LOGIN_URL, obj, {
        cors: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type"
        }
      });
}