import axios from './axios.interceptor';
import api from './api.util';

export const QUERY = `${api}/dashboard`;

export async function fetchDashboardData() {
    const asd = await axios.get(QUERY, {
        cors: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type"
        }
    });
    return asd;
}
