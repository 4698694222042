import React from "react";
// import BreadCrumb from "../../component/home-two/BreadCrumb";
import Layout from "../../component/home-two/Layout";
import useMenu from "../../hooks/useMenu";
import Sidebar from "../../component/ownerDetails/Sidebar";
import Wrapper from "../../component/ownerDetails/Wrapper";
import { Outlet } from "react-router-dom";

function Locations() {
  useMenu();
  return (
    <Layout>
      <Wrapper>
        <div className="row">
          <Sidebar />
          <div className="col-lg-9 col-md-10 col-12  crancy-personals__content mg-top-30">
            <Outlet />
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
}

export default Locations;
