import React from "react";
import profileImg from "../../assets/img/profile-single-thumb.png";
import socialImg from "../../assets/img/social-1.png";
import socialImg2 from "../../assets/img/social-2.png";
import socialImg3 from "../../assets/img/social-3.png";
import socialImg4 from "../../assets/img/social-4.png";
import socialImg5 from "../../assets/img/social-5.png";
import { useSelector, useDispatch } from "react-redux";

function SidebarProfile() {
  const userDetails = useSelector(state => state.selectedOwner.ownerInfo );
  return (
    <div className="col-lg-4  col-12 crancy-upinner__column1">
      <div className="crancy-upcard mg-top-30">
        <div className="crancy-upcard__thumb">
          <img src={profileImg} alt="#" />
        </div>
        <div className="crancy-upcard__heading">
          <h3 className="crancy-upcard__title">{userDetails.name}</h3>
          <div className="crancy-upcard__location">
           
            <p className="crancy-upcard__text crancy-pcolor">
              {userDetails.address}
            </p>
          </div>
        </div>
        <ul className="crancy-upcard__list">
          <li>
            <b>Mobile :</b> <span>+91 {userDetails.personalPhone}</span>
          </li>
          <li>
            <b>Email :</b> <span style={{fontSize:"15px"}}>{userDetails.email}</span>
          </li>
          
          
        </ul>
        <ul className="crancy-upcard__social">
          <li>
            <a href="#">
              <img src={socialImg} />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={socialImg2} />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={socialImg3} />
            </a>
          </li>
          <li>
            <a href="#">
              <img src={socialImg4} alt="social 4"/>
            </a>
          </li>
          <li>
            <a href="#">
              <img src={socialImg5} alt="social 5"/>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SidebarProfile;
