import React, { useState } from "react";
// import dotIcon from "../../assets/img/dots.svg";
// import { Link } from "react-router-dom";
import img1 from "../../assets/img/customer1.png";
import { Link } from "react-router-dom";
import UpdateLocation from "./UpdateLocation";
import { deleteLocation } from "../../crud/location.crud";
import DeleteCom from "./DeleteCom";
import CreateMetadata from "./CreateMetadata";
import UpdateMetadata from "./UpdateMetadata";
import { updateLocation } from "../../crud/location.crud";

function LocationItem({ location }) {
  const { _id, city_vil, locSlug, district, state, homestayC, metaFields, locationHitCount, locationActive } =
    location;
  const [isUpdateLocationOpen, setIsUpdateLocationOpen] = useState(false);
  const [isDeleteLocationOpen, setIsDeleteLocationOpen] = useState(false);
  const [isCreateMetaOpen, setIsCreateMetaOpen] = useState(false);
  const [isUpdateMetaOpen, setIsUpdateMetaOpen] = useState(false);
  const [locActive, setLocationActive] = useState(locationActive);
  let chkid = "checkbox" + _id;

  const toggleActiveStatus =() => {
    // Call updateLocation function to toggle locationActive status
    const ActLoc = {locationActive: !locActive }
    // const ActLoc = {city_vil: "aaaa--Update" }
    updateLocation(locSlug, ActLoc)
      .then((updatedLocation) => {
        console.log("Location updated:", updatedLocation);
        // setLocationActive(!locActive);
        // console.log("locationActive", locationActive);
      })
      .catch((err) => {
        console.log(err);
      });
    setLocationActive(!locActive);
    console.log("ActLoc",ActLoc);
  };
  console.log("locActive", locActive);
  const createMetaModal = (isOpen) => {
    setIsCreateMetaOpen(isOpen)
  }
  const updateMetaModal = (isOpen) => {
    setIsUpdateMetaOpen(isOpen)
  }
  const updateModal = (isOpen) => {
    setIsUpdateLocationOpen(isOpen);
  };

  const deleteModal = (isOpen) => {
    setIsDeleteLocationOpen(isOpen);
  };
  const LocationDelete = (slug) => {
    deleteLocation(slug)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <tr>
        <td className="crancy-table__column-1 crancy-table__data-1">
          <div className="crancy-table__customer">
            <div className="crancy-wc__checkbox">
              <label htmlFor={chkid} className="crancy-table__customer-img">
                <h4 className="crancy-table__product-title">{city_vil}</h4>
              </label>
            </div>
          </div>
        </td>
        <td className="crancy-table__column-2 crancy-table__data-2">
          <h4 className="crancy-table__product-title">{locSlug}</h4>
        </td>
        <td className="crancy-table__column-3 crancy-table__data-3">
          <h4 className="crancy-table__product-title">{district}</h4>
        </td>
        <td className="crancy-table__column-3 crancy-table__data-3">
          <h4 className="crancy-table__product-title">{state}</h4>
        </td>
        <td className="crancy-table__column-3 crancy-table__data-3">
          <h3 className={`crancy-table__product-title ${locationHitCount === 0 ? 'text-danger' : 'text-success'}`} style={{ fontWeight: "900" }}>{locationHitCount}</h3>
        </td>
        <td className="crancy-table__column-3 crancy-table__data-3">
          <h4 className={`crancy-table__product-title ${homestayC.length === 0 ? 'text-danger' : 'text-success'}`} style={{ fontWeight: "900" }}>{homestayC.length}</h4>
        </td>
        <td className="crancy-table__column-3 crancy-table__data-3">
          <h4 className="crancy-table__product-title">
            <button
              aria-label="none"
              type="button"
              className={`notification-switch ${locActive
                ? "notification-switch-active"
                : ""
                }`}
              role="switch"
              aria-checked={locActive}
              aria-labelledby="availability-label"
              aria-describedby="availability-description"
              // onClick={handleButtonClick}
              onClick={toggleActiveStatus} // Uncomment this line if you have a function to toggle the active status
            >
              <span
                aria-hidden="true"
                className={`pointer-events-none inline-block h-6 w-6 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${locActive
                  ? "translate-x-5"
                  : "translate-x-0"
                  }`}
              ></span>
            </button>
          </h4>
        </td>
        <td className="crancy-table__column-3 crancy-table__data-3">
          {metaFields.length === 0 ? (
            <Link onClick={() => createMetaModal(true)}>
              <i className="fa-solid fa-home ms-2 text-danger"></i>
            </Link>
          ) : (
            <Link onClick={() => updateMetaModal(true)}>
              <i className="fa-solid fa-home ms-2 text-success"></i>
            </Link>
          )}
          <Link onClick={() => updateModal(true)}>
            <i
              className="fa fa-solid ms-2 fa-pen-to-square text-success"
              aria-hidden="true"
            ></i>
          </Link>
          <Link onClick={() => deleteModal(true)}>
            <i className="fa-solid fa-trash ms-2 text-danger"></i>
          </Link>
        </td>
      </tr>
      {/* Modal */}
      {isUpdateLocationOpen && (
        <UpdateLocation
          locationSlug={locSlug}
          show={isUpdateLocationOpen}
          handleShow={updateModal}
        />
      )}
      {isDeleteLocationOpen && (
        <DeleteCom
          slug={locSlug}
          show={isDeleteLocationOpen}
          handleShow={deleteModal}
        />
      )}
      {isCreateMetaOpen && (
        <CreateMetadata
          slug={locSlug}
          name={city_vil}
          show={isCreateMetaOpen}
          handleShow={createMetaModal}
        />
      )}
      {isUpdateMetaOpen && (
        <UpdateMetadata
          locationSlug={locSlug}
          show={isUpdateMetaOpen}
          handleShow={updateMetaModal}
        />
      )}
    </>
  );
}

export default LocationItem;
