import { configureStore } from '@reduxjs/toolkit'

import authReducer from './features/authSlice';
import ownerReducer from './features/currentOwwnerSlice';
import homestayReducer from './features/currentHomestaySlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    selectedOwner: ownerReducer,
    selectedHomestay: homestayReducer,
  }
})
export default store;