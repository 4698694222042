import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoaderPage from "../templates/LoaderPage";
import { createHomestay } from "../../crud/homestay.crud";
import { fetchAllLocations } from "../../crud/location.crud";
import { fetchHomestayOwner } from "../../crud/homestayowner.crud";
import { fetchAllTags } from "../../crud/tag.crud";
import { useDispatch } from "react-redux";
import { storeHomestay } from "../../redux/features/currentHomestaySlice";
import Select from "react-select";

function ImageUploads({ className }) {
  const location = useLocation();
  const [selectedImage, setSelectedImage] =useState([]);
  const [progress, setProgress] =useState({started: false, pc:0});
  const [msg, setMsg] =useState(null);
 
  const onSelectFile = (event) =>{
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    const imagesArray = selectedFilesArray.map((file)=>{
      return URL.createObjectURL(file);
    })
    setSelectedImage((previousImages)=>previousImages.concat(imagesArray));
  }
  return (
    <div
      className={className ? className : "col-xxl-9 col-12 crancy-main__column"}
    >
      <label className="imgUploadLabel">
      + Add Images <br/>
      <span>up to 10 Images</span>
      <input type="file" name="images" onChange={onSelectFile} multiple accept="image/png, image/jpeg, image/jpg"></input>
      </label>
      <div className="images">
        {selectedImage && selectedImage.map((image,index)=>{
          return(
            <div key={index} className="image">
              <img src={image} height={200}/>
              <button onClick={()=> setSelectedImage(selectedImage.filter((e)=> e !== image))}>Delete Image</button>
            </div>
          )
        })}
      </div>
    </div>
  );
}

export default ImageUploads;
