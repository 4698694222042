import React, { useState } from "react";
// import BreadCrumb from "../../component/home-two/BreadCrumb";
import Layout from "../../component/home-two/Layout";
import InnerWrapper from "../../component/home-three/InnerWrapper";
import useMenu from "../../hooks/useMenu";
import UsersCom from "../../component/users/UsersCom";
import AddNewUser from "../../component/users/AddNewUser";


function Users() {
  
  useMenu();
  const [addTask, setAddTask] = useState(false);
  return (
    <Layout>
      <div className="row">
        <InnerWrapper>
          
          <AddNewUser show={addTask} handleShow={setAddTask} />
          <UsersCom addTask={setAddTask}/>
        </InnerWrapper>
      </div>
    </Layout>
  );
}

export default Users;
