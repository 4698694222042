import axios from './axios.interceptor';
import api from './api.util';

export const HomestayOwner = `${api}/users`;
export const Homestay = `${api}/api/v1/homestay`;

export async function fetchHomestayOwner() {
    const usrUL = `${HomestayOwner}/owner`;
    const asd = await axios.get(usrUL, {
        cors: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type"
        }
    });
    return asd;
}
export async function fetchSingleOwner(id) {
    const usrUL = `${HomestayOwner}/${id}`;
    const asd = await axios.get(usrUL, {
        cors: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type"
        }
    });
    return asd;
}
export async function fetchHomestaysUnderOwner(id) {
    // const usrUL = `${Homestay}/searchHomestaysLocation/peshok-homestay`;
    const usrUL = `${Homestay}/owner/${id}`;
    const asd = await axios.get(usrUL, {
        cors: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type"
        }
    });
    return asd;
}
export async function createHomestay(obj) {
    const asd = await axios.post(Homestay, obj, {
        cors: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type"
        }
    });
    return asd;
}

export async function updateOwner(id,obj) {
    const usrUL = `${HomestayOwner}/${id}`;
    return axios.put(usrUL, obj);
}

export async function deleteOwner(id) {
    const usrUL = `${HomestayOwner}/${id}`;
    return axios.delete(usrUL);
}