import React from "react";

import Layout from "../../../component/home-two/Layout";
import InnerWrapper from "../../../component/home-three/InnerWrapper";
import ImageUploads from "../../../component/homestayDetails/ImageUploads";



function ImageUpload() {
  return (
    <Layout>
      <div className="row">
        <InnerWrapper>
          <ImageUploads />
        </InnerWrapper>
      </div>
    </Layout>
  );
}

export default ImageUpload;
