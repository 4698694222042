import React, { useState, useEffect } from "react";
import { fetchAllTags,deleteTag } from "../../crud/tag.crud";
import LoaderPage from "../templates/LoaderPage";
import AddNewTag from "./AddNewTag";
import UpdateTag from "./UpdateTag";
import { Link } from "react-router-dom";
import DeleteCom from "./DeleteCom";
function HomestayTagsCom({ className }) {
  const [hsTags, setHsTags] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(25);
  const [filteredTags, setFilteredTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isAddTagsOpen, setIsAddTagsOpen] = useState(false);
  const [isUpdateTagsOpen, setIsUpdateTagsOpen] = useState(false);
  const [isDeleteLocationOpen, setIsDeleteLocationOpen] = useState(false);
  const [isSlug, setIsSlug]=useState("");

  const deleteModal = (isOpen,slug) =>{
    setIsDeleteLocationOpen(isOpen);
    setIsSlug(slug);
  }
  const [tagSlug, setTagSlug] = useState('');
  useEffect(() => {
    fetchAllTags().then((res) => {
      let htgs = res.data.filter((tgg) => tgg.tagType === "homestay");
      setHsTags(htgs);
      setIsDataLoaded(true);
    });
  }, []);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  // filter data
  useEffect(() => {
    const filteredData = hsTags.filter((transaction) =>
      transaction.tagName.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredTags(filteredData);
  }, [hsTags, inputValue]);
  const addTagsModal = (isOpen) => {
    setIsAddTagsOpen(isOpen);
  };
  const updateTagModal = (isOpen,slug) => {
    setIsUpdateTagsOpen(isOpen);
    setTagSlug(slug);
  };
  const tagDelete = (slug) =>{
    deleteTag(slug)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <>
      <div
        className={
          className ? className : "col-xxl-9 col-12 crancy-main__column"
        }
      >
        <div className="crancy-body">
          <div className="crancy-dsinner">
            <div className="crancy-table--v3 mt-3">
              <div className="crancy-customer-filter mb-0">
                <div></div>
                <div>
                  <div className="crancy-header__form crancy-header__form--customer">
                    <button
                      className="btn btn-primary "
                      onClick={() => addTagsModal(true)}
                    >
                      Add Homestay Tags
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="crancy-body">
          <div className="crancy-dsinner">
            <div className="crancy-table crancy-table--v3 mt-3">
              <div className="crancy-customer-filter">
                <div
                  className={`crancy-customer-filter__single ${
                    className
                      ? "crancy-customer-filter__single--csearch--history"
                      : "crancy-customer-filter__single--csearch"
                  }`}
                >
                  <div className="crancy-header__form crancy-header__form--customer">
                    <form className="crancy-header__form-inner" action="#">
                      <button className="search-btn" type="submit">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="9.78639"
                            cy="9.78614"
                            r="8.23951"
                            stroke="#9AA2B1"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></circle>
                          <path
                            d="M15.5176 15.9448L18.7479 19.1668"
                            stroke="#9AA2B1"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </button>
                      <input
                        name="s"
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder="Search by Homestay Tags"
                      />
                    </form>
                  </div>
                </div>
              </div>
              {!isDataLoaded ? (
                <LoaderPage />
              ) : (
                <table
                  id="crancy-table__main"
                  className="crancy-table__main crancy-table__main-v3"
                >
                  <thead className="crancy-table__head">
                    <tr>
                      <th className="crancy-table__column-1 crancy-table__h1">
                        <div className="crancy-wc__checkbox">
                          
                          <span>Tag Name </span>
                        </div>
                      </th>
                      <th className="crancy-table__column-2 crancy-table__h2">
                        Slug
                      </th>
                      <th className="crancy-table__column-2 crancy-table__h2">
                        Tag Status
                      </th>
                      <th className="crancy-table__column-4 crancy-table__h4">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="crancy-table__body">
                    {filteredTags
                      // .filter(htg => selectedDistrict === "Select District" || htg.district === selectedDistrict)
                      .map((htg, index) => {
                        const current = page * show;
                        const previous = current - show;
                        if (
                          previous > 0 &&
                          index + 1 > previous &&
                          index + 1 <= current
                        ) {
                          return (
                            <tr key={index}>
                              <td className="crancy-table__column-1 crancy-table__data-1">
                                <div className="crancy-table__customer">
                                  <div className="crancy-wc__checkbox">
                                    
                                    <label
                                      htmlFor="checkbox"
                                      className="crancy-table__customer-img"
                                    >
                                      <h4 className="crancy-table__product-title">
                                        {htg.tagName}
                                      </h4>
                                    </label>
                                  </div>
                                </div>
                              </td>
                              <td className="crancy-table__column-3 crancy-table__data-3">
                                <h4 className="crancy-table__product-title">
                                  {htg.tagSlug}
                                </h4>
                              </td>
                              <td className="crancy-table__column-3 crancy-table__data-3">
                                  <h4 className="crancy-table__product-title">
                                    active
                                  </h4>
                                </td>
                              <td className="crancy-table__column-3 crancy-table__data-3">
                                <Link 
                                onClick={() => updateTagModal(true,htg.tagSlug)}
                                >
                                  <i
                                    className="fa fa-solid fa-pen-to-square text-success"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                              </td>
                            </tr>
                          );
                        } else if (page == 1) {
                          return (
                            index < page * show && (
                              <tr key={index}>
                                <td className="crancy-table__column-1 crancy-table__data-1">
                                  <div className="crancy-table__customer">
                                    <div className="crancy-wc__checkbox">
                                      
                                      <label
                                        htmlFor="checkbox"
                                        className="crancy-table__customer-img"
                                      >
                                        <h4 className="crancy-table__product-title">
                                          {htg.tagName}
                                        </h4>
                                      </label>
                                    </div>
                                  </div>
                                </td>
                                <td className="crancy-table__column-3 crancy-table__data-3">
                                  <h4 className="crancy-table__product-title">
                                    {htg.tagSlug}
                                  </h4>
                                </td>
                                <td className="crancy-table__column-3 crancy-table__data-3">
                                  <h4 className="crancy-table__product-title">
                                    active
                                  </h4>
                                </td>
                                <td className="crancy-table__column-3 crancy-table__data-3">
                                <Link 
                                onClick={() => updateTagModal(true,htg.tagSlug)}
                                >
                                  <i
                                    className="fa fa-solid fa-pen-to-square text-success"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                                <Link 
                                onClick={() => deleteModal(true,htg.tagSlug)}
                                >
                                  <i className="fa-solid fa-trash ms-2 text-danger"></i>
                                </Link>
                              </td>
                              </tr>
                            )
                          );
                        }
                      })}
                  </tbody>
                </table>
              )}
              <div className="crancy-table-bottom">
                <div
                  id="crancy-table__main_filter"
                  className="dataTables_filter"
                >
                  <label>
                    Search:
                    <input
                      type="search"
                      className="form-control form-control-sm"
                      placeholder=""
                      aria-controls="crancy-table__main"
                    />
                  </label>
                </div>
                <div
                  className="dataTables_length"
                  id="crancy-table__main_length"
                  style={{ display: "flex" }}
                >
                  <label
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <span> Show result: </span>
                    <select
                      name="crancy-table__main_length"
                      aria-controls="crancy-table__main"
                      className="form-select form-select-sm"
                      defaultValue={25}
                      onChange={(e) => setShow(e.target.value)}
                    >
                      <option value="4">4</option>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                    </select>
                  </label>
                </div>
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="crancy-table__main_paginate"
                >
                  <ul className="pagination">
                    <li
                      className={`paginate_button page-item previous ${
                        page === 1 ? "disabled" : ""
                      }`}
                      id="crancy-table__main_previous"
                      onClick={() => page > 1 && setPage(page - 1)}
                    >
                      <a
                        aria-controls="crancy-table__main"
                        data-dt-idx="previous"
                        tabIndex="0"
                        className="page-link"
                      >
                        <i className="fas fa-angle-left"></i>
                      </a>
                    </li>
                    {Array.from(
                      Array(Math.ceil(filteredTags.length / show)).keys("n")
                    ).map((id, index) => (
                      <li
                        className={`paginate_button page-item ${
                          page === index + 1 ? "active" : ""
                        }`}
                        onClick={() => setPage(index + 1)}
                        key={index + "key"}
                      >
                        <a
                          aria-controls="crancy-table__main"
                          data-dt-idx="0"
                          tabIndex="0"
                          className="page-link"
                        >
                          {index + 1}
                        </a>
                      </li>
                    ))}
                    <li
                      className={`paginate_button page-item next ${
                        page === filteredTags.length % show < 1
                          ? "disabled"
                          : ""
                      }`}
                      id="crancy-table__main_next"
                      onClick={() =>
                        page < Math.ceil(filteredTags.length / show) &&
                        setPage(page + 1)
                      }
                    >
                      <a
                        aria-controls="crancy-table__main"
                        data-dt-idx="next"
                        tabIndex="0"
                        className="page-link"
                      >
                        <i className="fas fa-angle-right"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      {isAddTagsOpen && (
        <AddNewTag
          tagType={"homestay"}
          show={isAddTagsOpen}
          handleShow={addTagsModal}
        />
      )}
      {isUpdateTagsOpen && (
        <UpdateTag
          tagType={'homestay'}
          tagSlug={tagSlug}
          show={isUpdateTagsOpen}
          handleShow={updateTagModal}
        />
      )}
      {isDeleteLocationOpen && (
        <DeleteCom
          slug={isSlug}
          show={isDeleteLocationOpen}
          handleShow={deleteModal}
        />
      )}
    </>
  );
}

export default HomestayTagsCom;
