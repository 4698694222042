import React from "react";
import toggleIcon from "../../assets/img/toggle-icon.svg";
import { Link,useNavigate } from "react-router-dom";
import {deleteHomestay} from "../../crud/homestay.crud";
function HomestayOnrCard({ homestay }) {
  const {
    hs_name,
    homestay_slug,
    hs_address,
    hs_pincode,
    hs_contact,
    hs_email,
    hs_location,
    hs_price,
    hs_discount,
    hs_roomCount,
  } = homestay;
  const history = useNavigate();
  const HomestayDelete = (homestay_slug) => {
    deleteHomestay(homestay_slug)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="col-md-6 col-12">
      <div
        className="crancy-single-integration mg-top-30"
        style={{ border: "1px solid #cdcdcd" }}
      >
        <div className="crancy-toggle">
          <Link to={"/homestays/"+homestay_slug }>
            <i className="fa-solid fa-info-circle"></i>
          </Link>
        </div>
        <div className="crancy-single-integration__head">
          <div className="crancy-single-integration__heading">
            <h4
              className="crancy-single-integration__title"
              style={{ color: "#0a82fd" }}
            >
              {hs_name}
            </h4>
            <p className="crancy-single-integration__label">{hs_address}</p>
            <p>{hs_pincode}</p>
          </div>
        </div>
        <div className="crancy-single-integration__content">
          <p>Address : </p>
          <p>
            {hs_contact}, {hs_email}
          </p>
          <p>
            <span>Price : </span>
            <span style={{ border: "1px solid #cdcdcd", padding: "2px" }}>
              {hs_price}
            </span>{" "}
            <span>Discount :</span> {hs_discount}
          </p>
          <p>
            <span>Total Rooms :</span> {hs_roomCount}
          </p>
        </div>
      </div>
    </div>
  );
}

export default HomestayOnrCard;
