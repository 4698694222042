import React from "react";

import HomestayMeta from "../../../component/homestayDetails/HomestayMeta";

function HomestayMetadata() {
  return (
    <div className="tab-pane fade show active">
      <HomestayMeta/>
    </div>
  );
}

export default HomestayMetadata;
