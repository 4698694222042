import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LoaderPage from "../templates/LoaderPage";
import { fetchBackendUsers } from "../../crud/user.crud";
import DeleteCom from "./DeleteCom";

function UsersCom({ className,addTask }) {
  const [users, setUsers] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(25);
  const [isDeleteLocationOpen, setIsDeleteLocationOpen] = useState(false);
  const [isId, setIsId]=useState("");

  const deleteModal = (isOpen,id) =>{
    setIsDeleteLocationOpen(isOpen);
    setIsId(id);
  }
  useEffect(() => {
    fetchBackendUsers().then(res => {
      setUsers(res.data);
        setIsDataLoaded(true);
    });
  }, []);
  return (
    <div
      className={className ? className : "col-xxl-9 col-12 crancy-main__column"}
    >
      <div className="crancy-body">
        <div className="crancy-dsinner">
          <div className="crancy-table--v3 mt-3">
            <div className="crancy-customer-filter mb-0 p-0">
            <div className="mt-1"><h4>Owners</h4></div>
              <div>
                <div
                  className="crancy-todolist__button"
                  onClick={() => addTask(true)}
                >
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#task_modal"
                    className="crancy-btn crancy-sbcolor"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.09063 9.90925C7.91001 9.90925 7.77156 9.90925 7.63381 9.90925C5.46709 9.90925 3.30036 9.90995 1.13292 9.90784C0.993067 9.90784 0.849695 9.90854 0.714055 9.87903C0.284645 9.78556 -0.00209685 9.42435 1.15494e-05 8.99849C0.00211995 8.57334 0.292376 8.21424 0.722489 8.12428C0.858129 8.09618 1.0015 8.09828 1.14136 8.09828C3.30809 8.09688 5.47482 8.09758 7.64225 8.09758C7.78 8.09758 7.91775 8.09758 8.09063 8.09758C8.09063 7.924 8.09063 7.78697 8.09063 7.64993C8.09063 5.4363 8.08712 3.22267 8.09274 1.00903C8.09485 0.247964 8.77235 -0.218656 9.39784 0.1032C9.78719 0.303481 9.90596 0.650635 9.90456 1.07017C9.89964 3.26061 9.90245 5.45106 9.90245 7.6408C9.90245 7.77924 9.90245 7.91698 9.90245 8.09828C10.069 8.09828 10.2054 8.09828 10.341 8.09828C12.5548 8.09828 14.7686 8.09547 16.9824 8.10039C17.7478 8.1018 18.2159 8.76729 17.9003 9.39695C17.7042 9.78908 17.3591 9.91276 16.9396 9.91206C14.749 9.90784 12.559 9.90995 10.3684 9.90995C10.23 9.90995 10.0908 9.90995 9.90245 9.90995C9.90245 10.0695 9.90245 10.2051 9.90245 10.34C9.90245 12.4953 9.90245 14.6506 9.90245 16.8052C9.90245 16.9107 9.90737 17.0168 9.89894 17.1215C9.85958 17.6387 9.47444 18.0069 8.98389 17.9999C8.5081 17.9929 8.13491 17.6296 8.09485 17.1306C8.08642 17.0259 8.09134 16.9198 8.09134 16.8144C8.09134 14.671 8.09134 12.5277 8.09134 10.3843C8.09063 10.2438 8.09063 10.1046 8.09063 9.90925Z"
                        fill="white"
                      />
                    </svg>
                    Add User
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="crancy-body">
        <div className="crancy-dsinner">
          <div className="crancy-table crancy-table--v3 mt-3">
            <div className="crancy-customer-filter">
              <div
                className={`crancy-customer-filter__single ${
                  className
                    ? "crancy-customer-filter__single--csearch--history"
                    : "crancy-customer-filter__single--csearch"
                }`}
              >
              </div>
            </div>

            {!isDataLoaded ? (
              <LoaderPage />
            ) : (
              <table id="crancy-table__main" className="crancy-table__main crancy-table__main-v3" >
                <thead className="crancy-table__head">
                  <tr>
                    <th className="crancy-table__column-2 crancy-table__h2">
                      <span>Name</span>
                    </th>
                    <th className="crancy-table__column-2 crancy-table__h2">
                      <span>Location</span>
                    </th>
                    <th className="crancy-table__column-3 crancy-table__h3">
                      Email
                    </th>
                    <th className="crancy-table__column-4 crancy-table__h4">
                      Phone
                    </th>
                    <th className="crancy-table__column-4 crancy-table__h4">
                      Permission
                    </th>
                    <th className="crancy-table__column-4 crancy-table__h4">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="crancy-table__body ">
                  {users
                    // .filter(htg => selectedDistrict === "Select District" || htg.district === selectedDistrict)
                  .map((usr, index) => {
                    const current = page * show;
                    const previous = current - show;
                    if (
                      previous > 0 &&
                      index + 1 > previous &&
                      index + 1 <= current
                    ) {
                      return (
                        <tr key={index}>
                          <td className="crancy-table__column-3 crancy-table__data-3">
                            <h4 className="crancy-table__product-title">{usr.name}</h4>
                          </td>
                          <td className="crancy-table__column-3 crancy-table__data-3">
                            <h4 className="crancy-table__product-title">{usr.city_vil}</h4>
                          </td>
                          <td className="crancy-table__column-3 crancy-table__data-3">
                            <h4 className="crancy-table__product-title">
                            {usr.email}
                            </h4>
                          </td>
                          <td className="crancy-table__column-3 crancy-table__data-3">
                            <h4 className="crancy-table__product-title">{usr.personalPhone}</h4>
                          </td>
                          <td className="crancy-table__column-3 crancy-table__data-3">
                            <h4 className="crancy-table__product-title"></h4>
                          </td>
                          <td className="crancy-table__column-3 crancy-table__data-3">
                          <Link to={"/owners/100"}><i className="fa-solid fa-info-circle"></i></Link>
                          </td>
                        </tr>
                      );
                    } else if (page === 1) {
                      return (
                        index < page * show && (
                          <tr key={index}>
                            <td className="crancy-table__column-3 crancy-table__data-3">
                              <h4 className="crancy-table__product-title">{usr.name}</h4>
                            </td>
                            <td className="crancy-table__column-3 crancy-table__data-3">
                              <h4 className="crancy-table__product-title">{usr.city_vil}</h4>
                            </td>
                            <td className="crancy-table__column-3 crancy-table__data-3">
                              <h4 className="crancy-table__product-title">
                              {usr.email}
                              </h4>
                            </td>
                            <td className="crancy-table__column-3 crancy-table__data-3">
                              <h4 className="crancy-table__product-title">{usr.personalPhone}</h4>
                            </td>
                            <td className="crancy-table__column-3 crancy-table__data-3">
                              <h4 className="crancy-table__product-title"></h4>
                            </td>
                            <td className="crancy-table__column-3 crancy-table__data-3">
                            <Link to={`/users/${usr._id}`}><i className="fa-solid fa-info-circle"></i></Link>
                            <Link onClick={() => deleteModal(true,usr._id)} className="ms-1">
                                  <i
                                    className="fa-solid fa-trash"
                                    style={{ color: "red" }}
                                  ></i>
                                </Link>
                            </td>
                          </tr>
                        )
                      );
                    }
                  })}
                </tbody>
              </table>
            )}
            <div className="crancy-table-bottom">
              <div id="crancy-table__main_filter" className="dataTables_filter">
                <label>
                  Search:
                  <input
                    type="search"
                    className="form-control form-control-sm"
                    placeholder=""
                    aria-controls="crancy-table__main"
                  />
                </label>
              </div>
              <div
                className="dataTables_length"
                id="crancy-table__main_length"
                style={{ display: "flex" }}
              >
                <label
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <span> Show result: </span>
                  <select
                    name="crancy-table__main_length"
                    aria-controls="crancy-table__main"
                    className="form-select form-select-sm"
                    defaultValue={25}
                    onChange={(e) => setShow(e.target.value)}
                  >
                    <option value="4">4</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </select>
                </label>
              </div>
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="crancy-table__main_paginate"
              >
                <ul className="pagination">
                  <li
                    className={`paginate_button page-item previous ${
                      page === 1 ? "disabled" : ""
                    }`}
                    id="crancy-table__main_previous"
                    onClick={() => page > 1 && setPage(page - 1)}
                  >
                    <a
                      aria-controls="crancy-table__main"
                      data-dt-idx="previous"
                      tabIndex="0"
                      className="page-link"
                    >
                      <i className="fas fa-angle-left"></i>
                    </a>
                  </li>
                  {Array.from(
                    Array(Math.ceil(users.length / show)).keys("n")
                  ).map((id, index) => (
                    <li
                      className={`paginate_button page-item ${
                        page === index + 1 ? "active" : ""
                      }`}
                      onClick={() => setPage(index + 1)}
                      key={index + "key"}
                    >
                      <a
                        aria-controls="crancy-table__main"
                        data-dt-idx="0"
                        tabIndex="0"
                        className="page-link"
                      >
                        {index + 1}
                      </a>
                    </li>
                  ))}
                  <li
                    className={`paginate_button page-item next ${
                      page === users.length % show < 1 ? "disabled" : ""
                    }`}
                    id="crancy-table__main_next"
                    onClick={() =>
                      page < Math.ceil(users.length / show) &&
                      setPage(page + 1)
                    }
                  >
                    <a aria-controls="crancy-table__main" data-dt-idx="next" tabIndex="0" className="page-link">
                    <i className="fas fa-angle-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      {isDeleteLocationOpen && (
        <DeleteCom
          id={isId}
          show={isDeleteLocationOpen}
          handleShow={deleteModal}
        />
      )}
    </div>
  );
}

export default UsersCom;
