import axios from './axios.interceptor';
import api from './api.util';

export const USER = `${api}/users`;

export function fetchAllUsers() {
    return axios.get(USER);
}
export function fetchBackendUsers() {
    const usrUL = `${USER}/back`;
    return axios.get(usrUL);
}
export function fetchSingleUser(id) {
    const locUL = `${USER}/${id}`;
    return axios.get(locUL);
}
// export function searchCatName(txt) {
//     const locUL = `${USER}/search/${txt}`;
//     return axios.get(locUL);
// }

export function createUser( obj ) {
    const usrUL = `${USER}/register`;
    const asd = axios.post(usrUL, obj, {
      cors: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type"
      }
    });
    return asd;
}

export function updateUser(id,city_vil, lat, lang, state, tags) {
    const locUL = `${USER}/${id}`;
    return axios.put(locUL, { city_vil, lat, lang, state, tags });
}

export function deleteUser(id) {
    const locUL = `${USER}/${id}`;
    return axios.delete(locUL);
}