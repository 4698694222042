import React from "react";
import HomestayTagsCom from "../../../component/tags/HomestayTagsCom";

function HomestayTag() {
  return (
    <div className="tab-pane fade show active">
      <HomestayTagsCom/>
    </div>
  );
}

export default HomestayTag;
