import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { createHomeMetatags, fetchSingleHomeMetatags, updateHomeMetaTags } from "../../crud/homestay.crud";
function HomestayMeta({ className }) {
    const history = useNavigate();
    const location = useLocation();
    const pathSegments = location.pathname
        .split("/")
        .filter((segment) => segment !== "");
    const homestaySlug = pathSegments.length > 0 ? pathSegments[1] : null;

    const [homestayMeta, setHomestaysMeta] = useState({});
    const [buttonClicked, setButtonClicked] = useState(false);

    // console.log( "homestayMeta",homestayMeta);
    const [values, setValues] = useState({
        homeSlug: homestaySlug,
        metaTitle: "",
        metaDescription: "",
        metaKeyword: "",
    });


    useEffect(() => {
        fetchSingleHomeMetatags(homestaySlug).then((res) => {
            setHomestaysMeta(res.data);
            console.log(res.data);
        });

    }, [homestaySlug]);
    const handleChangess = (e) => {
        var name = e.target.name;
        var val = e.target.value;
        let newVal = {};
        switch (name) {
            case "metaTitle":
                newVal = { ...values, metaTitle: val };
                break;
            case "metaDescription":
                newVal = { ...values, metaDescription: val };
                break;
            case "metaKeyword":
                newVal = { ...values, metaKeyword: val };
                break;
            default:
                break;
        }
        setValues(newVal);
    }
    const handleChange = (e, propertyName) => {
        // const name = e.target.name;
        const val = e.target.value;
        setHomestaysMeta((prevDetails) => {
            return {
                ...prevDetails,
                [propertyName]: val,
            };
        });

    };
    const createHomestayMetaSubmit = (e) => {

        console.log("Homestay Meta Data : ", values);
        createHomeMetatags(values)
            .then((res) => {
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });
        setButtonClicked(true);
        setTimeout(() => {
          setButtonClicked(false);
        }, 8000);
    };

    const updateHomeMetaSubmit = (e) => {
        e.preventDefault();
        // const locSlug = metaTags[0].locSlug;
        // const locUp = metaTags[0];

        // console.log("homestayMeta",homestayMeta);
        updateHomeMetaTags(homestaySlug, homestayMeta)
            .then((res) => {
                history(`/homestays/${homestaySlug}/metadata`);
            })
            .catch((err) => {
                console.log(err);
            });
        setButtonClicked(true);
        setTimeout(() => {
            setButtonClicked(false);
        }, 5000);

    };
    return (
        <>
            <div
                className={
                    className ? className : "col-xxl-9 col-12 crancy-main__column"
                }
            >
                <div className="crancy-body">
                    <div className="crancy-dsinner">
                        {!homestayMeta ? (
                            <div className="crancy-table--v3 mt-3">
                                <div className="row">
                                    <div className="col-12">
                                        <input
                                            className="form-control"
                                            name="metaTitle"
                                            value={values.metaTitle}
                                            onChange={handleChangess}
                                            placeholder="Homestay Meta Title"
                                        />
                                    </div>
                                    <div className="col-12 mt-3">
                                        <textarea
                                            typeof="text"
                                            className="form-control"
                                            name="metaDescription"
                                            value={values.metaDescription}
                                            onChange={handleChangess}
                                            style={{ height: "150px" }}
                                            placeholder="Homestay Meta Description"
                                        />
                                    </div>
                                    <div className="col-12 mt-3">
                                        <input
                                            className="form-control"
                                            name="metaKeyword"
                                            value={values.metaKeyword}
                                            onChange={handleChangess}
                                            placeholder="Homestay Meta Keyword"
                                        />
                                    </div>
                                    <div className="col-12 mt-3">
                                        <button type="submit" className="btn btn-primary" onClick={() => createHomestayMetaSubmit()} disabled={buttonClicked}
                                            style={{
                                                cursor: buttonClicked ? "not-allowed" : "pointer",
                                            }}>Submit</button>
                                    </div>
                                </div>

                            </div>
                        ) : (
                            <div className="crancy-table--v3 mt-3">
                                <div className="row">
                                    <div className="col-12">
                                        <input
                                            className="form-control"
                                            name="title"
                                            id="title"
                                            value={homestayMeta.metaTitle}
                                            onChange={(e) => handleChange(e, "metaTitle")}
                                            placeholder="Homestay Meta Title"
                                        />
                                    </div>
                                    <div className="col-12 mt-3">
                                        <textarea
                                            typeof="text"
                                            className="form-control"
                                            name="desc"
                                            id="desc"
                                            value={homestayMeta.metaDescription}
                                            onChange={(e) => handleChange(e, "metaDescription")}
                                            style={{ height: "150px" }}
                                            placeholder="Homestay Meta Description"
                                        />
                                    </div>
                                    <div className="col-12 mt-3">
                                        <input
                                            className="form-control"
                                            name="keyword"
                                            id="keyword"
                                            value={homestayMeta.metaKeyword}
                                            onChange={(e) => handleChange(e, "metaKeyword")}
                                            placeholder="Homestay Meta Keyword"
                                        />
                                    </div>
                                    <div className="col-12 mt-3">
                                        <button type="submit" className="btn btn-primary" onClick={updateHomeMetaSubmit} disabled={buttonClicked}
                                            style={{
                                                cursor: buttonClicked ? "not-allowed" : "pointer",
                                            }}>Update</button>
                                    </div>
                                </div>

                            </div>
                        )}

                    </div>
                </div>

            </div>

        </>
    );
}

export default HomestayMeta;
