import React, {useState} from "react";
// import BreadCrumb from "../../component/home-two/BreadCrumb";
import { Outlet } from "react-router-dom";
import Layout from "../../component/home-two/Layout";
// import ActivitySection from "../../component/home/ActivitySection";
import InnerWrapper from "../../component/home-three/InnerWrapper";
// import AnalyticsConversions from "../../component/home/AnalyticsConversions";
import TagsList from "../../component/tags/TabList";
import useMenu from "../../hooks/useMenu";
import AddNewTag from "../../component/tags/AddNewTag";
// import Sidebar from "../../component/home/Sidebar";

function Locations() {
  const [addTask, setAddTask] = useState(false);
  useMenu();
  return (
    <Layout>
      <div className="row">
        <InnerWrapper>
        <AddNewTag show={addTask} handleShow={setAddTask} />
          <div className="crancy-upcontent mg-top-30">
            <TagsList />
            <div className="tab-content" id="nav-tabContent">
              <Outlet />
            </div>
          </div>
        </InnerWrapper>
      </div>
    </Layout>
  );
}

export default Locations;
