import { createBrowserRouter, RouterProvider,useNavigate  } from "react-router-dom";
import Layout from "./component/layout";
import LoginLayout from "./component/layout/LoginLayout";
import Login from "./pages/login";
import Home from "./pages/home";
import Locations from "./pages/locations";
import Owners from "./pages/owners";
import Homestays from "./pages/homestays";
import Tags from "./pages/tags";
import HomestayDetails from "./pages/homestayDetails";
import HomestayInfo from "./pages/homestayDetails/homestayInfo";
import CreateHomestay from "./pages/homestays/createHomestay";
import CreateOwnerHomestay from "./pages/homestays/createOwnerHomestay";
import OwnerDetails from "./pages/ownerDetails";
import UserDetails from "./pages/userDetails";
import LocationTag from "./pages/tags/locationTag";
import HomestayTag from "./pages/tags/homestayTag";
import PersonalInfo from "./pages/ownerDetails/personal-info";
import Transactions from "./pages/ownerDetails/transactions";
import Queries from "./pages/ownerDetails/queries";
import OwnerHomestays from "./pages/ownerDetails/homestays";
import ChangePassword from "./pages/ownerDetails/change-password";
import Users from "./pages/users";
// import { useSelector } from 'react-redux';
import Booking from "./pages/booking";
import ImageUpload from "./pages/homestays/imageUpload";
import ProtectedRoute from "./component/ProtectedRoute";
import HomestayMetadata from "./pages/homestayDetails/homestayMetadata";
import HomestayImageUpload from "./pages/homestayDetails/homestayImageUpload";


// const ProtectedRoute = (props) => {
//   const {Element} =props;
//   const navigate = useNavigate();
//   const token = localStorage.getItem('token');
//   if (!token) {
//     navigate('/login');
//     return null;
//   }

//   return <Element />;
// };

const router = createBrowserRouter([
  {
    path: "/",
    Component: LoginLayout,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
    ],
  },
  {
    path: "/",
    Component: Layout,
    children: [
      {
        index: true,
        element: <ProtectedRoute Element={Home} />,
        // element: <Home/>
      },
      {
        path: "/locations",
        element: <ProtectedRoute Element={Locations} />,

        // element: <Locations />,
      },
      {
        path: "/booking-queries",
        element: <ProtectedRoute Element={Booking} />,
        // element: <Booking />,
      },
      {
        path: "/homestays",
        element: <ProtectedRoute Element={Homestays} />,
        // element:<Homestays/>
      },
      {
        path: "/create-homestay",
        element: <ProtectedRoute Element={CreateHomestay} />,
        // element: <CreateHomestay />,
      },
      {
        path: "/homestay/image-upload/:slug",
        element: <ProtectedRoute Element={ImageUpload} />,
        // element: <ImageUpload />,
      },
      {
        path: "/homestays/:slug",
        Component: HomestayDetails,
        children: [
          {
            index: true,
            element: <ProtectedRoute Element={HomestayInfo} />,
            // element: <HomestayInfo />,
          },
          {
            path: "transaction",
            element: <ProtectedRoute Element={HomestayTag} />,
            // element: <HomestayTag />,
          },
          {
            path: "metadata",
            element: <ProtectedRoute Element={HomestayMetadata} />,
            // element: <HomestayTag />,
          },
          {
            path: "imageupload",
            element: <ProtectedRoute Element={HomestayImageUpload} />,
            // element: <HomestayTag />,
          },
        ],
      },
      {
        path: "/tags",
        Component: Tags,
        children: [
          {
            index: true,
            element: <ProtectedRoute Element={LocationTag} />,
            // element: <LocationTag />,
          },
          {
            path: "homestay-tags",
            element: <ProtectedRoute Element={HomestayTag} />,
            // element: <HomestayTag />,
          },
        ],
      },
      {
        path: "/owners",
        element: <ProtectedRoute Element={Owners} />,
        // element: <Owners />,
      },
      {
        path: "/owners/:id/owner-homestays/create",
        element: <ProtectedRoute Element={CreateOwnerHomestay} />,
        // element: <CreateOwnerHomestay />,
      },
      // {
      //   path: "",
      //   element: <OwnerDetails />,
      // },
      {
        path: "/owners/:id",
        Component: OwnerDetails,
        children: [
          {
            index: true,
            element: <ProtectedRoute Element={PersonalInfo} />,
            // element: <PersonalInfo />,
          },
          {
            path: "transactions",
            element: <ProtectedRoute Element={Transactions} />,
            // element: <Transactions />,
          },
          {
            path: "queries",
            element: <ProtectedRoute Element={Queries} />,
            // element: <Queries />,
          },
          {
            path: "owner-homestays",
            element: <ProtectedRoute Element={OwnerHomestays} />,
            // element: <OwnerHomestays />,
          },
          {
            path: "change-password",
            element: <ProtectedRoute Element={ChangePassword} />,
            // element: <ChangePassword />,
          },
        ],
      },
      {
        path: "/users",
        element: <ProtectedRoute Element={Users} />,
        // element: <Users />,
      },
      {
        path: "/users/:id",
        Component: UserDetails,
        children: [
          {
            index: true,
            element: <ProtectedRoute Element={PersonalInfo} />,
            // element: <PersonalInfo />,
          },
          // {
          //   path: "transactions",
          //   element: <Transactions />,
          // },
          // {
          //   path: "queries",
          //   element: <Queries />,
          // },
          // {
          //   path: "owner-homestays",
          //   element: <OwnerHomestays />,
          // },
          // {
          //   path: "change-password",
          //   element: <ChangePassword />,
          // },
        ],
      },
    ],
  },
]);

function Router() {
  return <RouterProvider router={router} />;
}

export default Router;
