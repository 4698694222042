import React from "react";

import HomestayImageUploads from "../../../component/homestayDetails/HomestayImageUploads";

function HomestayImageUpload() {
  return (
    <div className="tab-pane fade show active">
      <HomestayImageUploads/>
    </div>
  );
}

export default HomestayImageUpload;
