import React from "react";
import Layout from "../../component/home-two/Layout";
import InnerWrapper from "../../component/home-three/InnerWrapper";
import useMenu from "../../hooks/useMenu";
import HomestaysCom from "../../component/homestays";

function Locations() {
  useMenu();
  return (
    <Layout>
      <div className="row">
        <InnerWrapper>
          <HomestaysCom />
        </InnerWrapper>
      </div>
    </Layout>
  );
}

export default Locations;
