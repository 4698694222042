import axios from './axios.interceptor';
import api from './api.util';

export const LOCATION = `${api}/locations`;

export async function fetchAllLocations() {
  const asd = await axios.get(LOCATION, {
    cors: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type"
    }
  });
  return asd;
}
export async function fetchSingleLocation(slug) {
  const locUL = `${LOCATION}/${slug}`;
  
  const asd = await axios.get(locUL, {
    cors: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type"
    }
  });
  return asd;
}

export function createLocation( obj ) {
  const asd = axios.post(LOCATION, obj, {
    cors: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type"
    }
  });
  return asd;
}

export async function updateLocation(slug,obj) {
  const usrUL = `${LOCATION}/${slug}`;
  return axios.put(usrUL, obj, {
    cors: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type"
    }
  });
}
export async function toggleLocPermission(slug,statuss) {
  const locUL = `${LOCATION}/toggle/${slug}/${statuss}`;
  
  const asd = await axios.get(locUL, {
    cors: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type"
    }
  });
  return asd;
}

// export async function deleteLocation(arr) {
//   let asd;
//   if(arr.length === 1) {
//     let id = arr[0]._id;
//     const locUL = `${LOCATION}/${id}`;
//     asd = await axios.delete(locUL, {
//       cors: {
//         "Access-Control-Allow-Origin": "*",
//         "Access-Control-Allow-Headers": "Content-Type"
//       }
//     });
//   } else {
//     asd = await arr.map( ar => {
//       let id = ar._id;
//       const locUL = `${LOCATION}/${id}`;
//       return axios.delete(locUL, {
//           cors: {
//             "Access-Control-Allow-Origin": "*",
//             "Access-Control-Allow-Headers": "Content-Type"
//           }
//       });
//     });
//   }
//   return asd;
// }
export async function deleteLocation(slug) {
  const usrUL = `${LOCATION}/${slug}`;
  return axios.delete(usrUL, {
    cors: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type"
    }
  });
}
export async function fetchSingleMetatags( slug ) {
  const url = `${LOCATION}/metatags/${slug}`
  const asd = await axios.get(url, {
    cors: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type"
    }
  });
  return asd;
}
export function createMetatags( obj ) {
  const url = `${LOCATION}/metatags`
  const asd = axios.post(url, obj, {
    cors: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type"
    }
  });
  return asd;
}
export async function updateMetaTags(slug,obj) {
  const usrUL = `${LOCATION}/metatags/${slug}`;
  return axios.put(usrUL, obj, {
    cors: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type"
    }
  });
}